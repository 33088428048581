import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextArea, Tag, TagSkeleton } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import './Onboarding.css';
import { FilterUser } from '../APIController/APIControllers';

const InviteOnboarding = () => {
    const navigate = useNavigate();
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [VerificationError, setVerificationError] = useState('');
    const [ValidationConditions, setValidationConditions] = useState(false);
    const [ListOfUsers, setListOfUsers] = useState(null);
    const EmailRegex=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        FilterUser(inputValue)
            .then((data) => {
                setListOfUsers(data)
            })
            .catch((err) => {
                console.log("error", err)
            })

           
    }, [inputValue])


    const Validation = () => {
        let IsValid = true;

        if (!inputValue) {
            setVerificationError("Email is required");
            IsValid = false;
        } else if (!EmailRegex.test(inputValue)) {
            setVerificationError("Enter a Valid email format");
            IsValid = false;
        } else if (ListOfUsers ) {
            IsValid = false;
            setVerificationError("User already exists.");
        } else if(emails&&emails.includes(inputValue)){
            IsValid = false;
            setVerificationError("Email already exist in invite list.")
        }
        else {
            setVerificationError(null)
        }
        return IsValid;
    }

    const handleKeyPress = (event) => {
        if ((event.key === ' ' && inputValue.trim() !== '') || (event.key === 'Enter' && inputValue.trim() !== '')) {
            const Validate=Validation();
            if(Validate){
                setValidationConditions(false)
            event.preventDefault();
            setEmails([...emails, inputValue.trim()]);
            setInputValue('');
            }
            else{
                setValidationConditions(true)
            }
        }
    };

    const handleTagClick = (emailToRemove) => {
        const newEmails = emails.filter((email) => email !== emailToRemove);
        setEmails(newEmails);
    };

    const handleButtonclick = () => {
        navigate('/thank-you');
    };

    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Invite your team to begin your ESG Audyting</div>
                <div className="Onboarding-Body">
                    User will be added to Audyt Organization Name once the user accepts the invitation sent on the given email address.
                </div>
                <div className="invite-onboarding-form">
                    <TextArea
                        id="address"
                        labelText="Enter multiple email addresses"
                        invalid={ValidationConditions&&VerificationError!==null}
                        invalidText={VerificationError}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    >

                    </TextArea>
                    <div className="tag-container">
                        {emails.map((email, index) => (
                            <Tag key={index} type="blue" onClick={() => handleTagClick(email)} className="tag-close-icon">
                                {email} <span className="tag-close-icon"> x </span>
                            </Tag>
                        ))}
                    </div>

                </div>
                <Button className="onboarding-button" onClick={handleButtonclick} renderIcon={ArrowRight}>
                    Set up your Audyt Organisation
                </Button>
            </div>
        </div>
    );
};

export default InviteOnboarding;
