import React, { useEffect, useState } from 'react';
import { fugitive_emissions, mobile_combustion } from './Calc';

const Testpage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await mobile_combustion()
            .then((data)=>{
                setData(data.data);
            })
        };

        fetchData();
    }, []);

    return (
        <div>
            <pre>Calculated total: {data&&data.attributes&&data.attributes.Calculated}</pre>
        </div>
    );
};

export default Testpage;
