import React, { useEffect, useRef, useState } from 'react';
import { AdminLoginJWT, LoginUserGrabJwt } from '../../APIController/AdministrationController';
import { Button, Header, HeaderName, TextInput } from '@carbon/react';
import LoginImage from "../../Images/ImageLogin.svg";
import { ArrowRight, Cloud, Email } from '@carbon/icons-react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Information } from '@carbon/icons-react';
import { Input } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { passwordlessCode, passwordlessSignupConsume, tenant_exist } from '../SupertokenController';

const OTPSignup = () => {
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const [resendTimer, setResendTimer] = useState(15);

    const handleOnNavigate = () => {
        navigate('/login')
    }

    const handleInputChange = (index, value) => {
        // Update the verification code array with the input value at the specified index
        const updatedCode = [...verificationCode];
        updatedCode[index] = value;
        setVerificationCode(updatedCode);

        // Move to the next input field
        if (value !== '' && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if ((e.key === 'Backspace' || e.key === 'Delete') && index > 0 && verificationCode[index] === '') {
            // If backspace or delete is pressed and the current input is empty, move to the previous input
            inputRefs.current[index - 1].focus();
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };
    useEffect(() => {
        // Countdown logic for the resend timer
        let timerId;

        if (resendTimer > 0) {
            timerId = setInterval(() => {
                setResendTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            // Cleanup the timer when the component unmounts
            clearInterval(timerId);
        };
    }, [resendTimer]);

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain').trim();

        if (/^\d{6}$/.test(pastedData)) {
            // If the pasted data is a 6-digit code, update the verificationCode state
            setVerificationCode(pastedData.split(''));

            // Focus on the last input field
            inputRefs.current[5].focus();
        }
    };

    const email = sessionStorage.getItem('email')


    const verifyCode = async () => {
        const OTP = verificationCode.join('');

        console.log('Verification Code:', OTP);
        const verifyBody = await passwordlessSignupConsume(OTP);

      


        try {
            const exist = await tenant_exist();
            console.log('Tenant exists:', exist);

            if (exist) {
                // Tenant exists, navigate to '/'
                navigate('/');
            } else {
                // Tenant does not exist, navigate to '/create-organisation'
                navigate('/create-organisation');
            }
        } catch (error) {
            // Handle any errors that may occur during the tenant_exist function call
            console.error('Error checking tenant existence:', error);
        }
        // navigate('/create-organisation')




    }
    const resendcode = async () => {
        const verifyBody = await passwordlessCode(email);
        console.log(verifyBody);
        window.location.reload();
    }


    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Sign up for Audyt ESG</div>
                <div className="Onboarding-Body">
                    A 6 digit PIN has been sent to <span className='bold-link'>{email}</span>. Enter the PIN to verify your email address.
                </div>
                <div className='input-boxes'>
                    {verificationCode.map((digit, index) => (
                        <TextInput
                            maxLength="1"
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={(e) => handlePaste(e)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="OTP-Box" /* Add CSS class for individual input styling */
                        />
                    ))}

                </div>
                <Button className='primary-login-button' onClick={verifyCode} renderIcon={Email}>
                    Verify your email address
                </Button>

                <div className='helper-container'>
                    Resend your One Time Password (OTP)  {resendTimer > 0
                        ? <>
                            <strong>
                                {formatTime(resendTimer)} seconds
                            </strong>
                        </>
                        : <>
                            <span className='bold-link' onClick={resendcode}>
                                click here
                            </span>
                        </>}
                </div>
                <div className='Signup-container'>
                    Already have an account? &nbsp;
                    <div className='bold-link' onClick={handleOnNavigate}> {" "} Login {">"}</div>
                </div>
            </div>
        </div>
    )
}

export default OTPSignup