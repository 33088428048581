// Calculations

import { getAlldata, updateData } from "../APIController/APIControllers"
import { volume } from "units-converter";
import convert from 'convert';
// Get Emission Factor Library




// Unit Conversions






// Fugitive emissions
export const fugitive_emissions = async () => {
    // get all fugitive emission submissions
    const fugitive_emissions_data = await getAlldata('fugitive-emissions');
    const totalEmission = fugitive_emissions_data && fugitive_emissions_data.data && fugitive_emissions_data.data.reduce((total, item) => {
        const quantity = item.attributes.Quantity;
        const globalWarmingPotentialFactor = item.attributes.greenhouse_gas.data.attributes.Global_Warming_Potential_Factor;
        const emission = quantity * globalWarmingPotentialFactor;
        return total + emission;
    }, 0);
    // Write single collection update calc()

    const payload = {
        Calculated: JSON.stringify(totalEmission)
    }

    const response = await updateData('calculation-fugitive-emission', payload)
        .then((data) => {
            return data
        })
        .catch((err) => err)

    return response;
}


// mobile combustion: need to report fuel*EF (fuel type)
export const mobile_combustion = async () => {
    const mobile_combustion_data = await getAlldata('mobile-combustions');
    console.log(mobile_combustion_data)

    // Get emission factors data
    const emission_factors_data = await getAlldata('emission-factors');
    console.log(emission_factors_data)

    // Extract fuel type from mobile_combustion_data and find the corresponding CO2 emission
    for (const combustion of mobile_combustion_data.data) {
        const fuelType = combustion.attributes.Fuel_type.data.attributes.Name;

        // Find the corresponding emission factor
        const emissionFactor = emission_factors_data.data.find(factor => factor.attributes.Name === fuelType);
        const emissionFactorCO2 = emissionFactor.attributes.CO2;
        const emissionFactorCH4 = emissionFactor.attributes.CH4;
        const emissionFactorN2O = emissionFactor.attributes.N2O;
        const emissionFactorHCFs = emissionFactor.attributes.HCFs;
        console.log("CO2 Emission:", emissionFactor.attributes.CO2);
        console.log("CH4 Emission:", emissionFactorCH4);
        console.log("N2O Emission:", emissionFactor.attributes.N2O);
        console.log("HCFs Emission:", emissionFactor.attributes.HCFs);



        if (emissionFactor) {
            console.log("Fuel Type:", fuelType);

            const isOnRoad = combustion.attributes.vehicle_type.data.attributes.Is_on_road;
            console.log(isOnRoad)
            const emissionFactorValue = emissionFactor.attributes.CO2;

            if (isOnRoad === "Yes") {
                const distance = combustion.attributes.Distance;
                const totalEmission = distance * emissionFactorValue;
                console.log("Total Emission:", totalEmission);
            } else {
                const fuelQuantityLiters = combustion.attributes.Fuel_quantity;
                const fuelQuantityGallons = fuelQuantityLiters * 0.264172; // Conversion from liters to gallons
                const test = convert(fuelQuantityLiters,'litres').to('gallons')
                const totalEmission = fuelQuantityGallons * emissionFactorValue;
                console.log("Total Emission:", totalEmission);
            }
        } else {
            console.log("Emission factor not found for the given fuel type:", fuelType);
        }
    }
}

