// Onboarding.js
import React, { useState, useEffect } from 'react';
import { Button, DatePicker, DatePickerInput, Dropdown, TextInput } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import "./Onboarding.css";
import { useNavigate } from 'react-router-dom';

const CreateReportingYear = () => {
    const [formData_02, setFormData_02] = useState({
        Reporting_Year: '',
        Date: '',
        Reporting_Template: '',
    })

    const [Validations, setValidation] = useState({
        Reporting_YearError: '',
        DateError: '',
        Reporting_TemplateError: '',
    });
    const [ValidateCondition, setValidateCondition] = useState(false);

    const reporting_template = ["Annual", "Semi-Annual", "Quarter", "Month", "4 week period"]
    const navigate = useNavigate();


    useEffect(() => {
        const parsedData = JSON.parse(sessionStorage.getItem("Step_02"));
        if (parsedData) {
            setFormData_02(parsedData);
        }
    }, [])


    const Validation = () => {
        let isValid = true;

        if (!formData_02.Reporting_Year) {
            isValid = false;
            setValidation(prevValidations => ({ ...prevValidations, Reporting_YearError: "Reporting year is required." }));
        } else {
            setValidation(prevValidations => ({ ...prevValidations, Reporting_YearError: null }))
        }

        if (!formData_02.Date) {
            isValid = false;
            setValidation(prevValidations => ({ ...prevValidations, DateError: "Date is required." }))
        } else {
            setValidation(prevValidations => ({ ...prevValidations, DateError: null }));
        }

        if (!formData_02.Reporting_Template) {
            isValid = false;
            setValidation(prevValidations => ({ ...prevValidations, Reporting_TemplateError: "Reporting Template is required." }));
        } else {
            setValidation(prevValidations => ({ ...prevValidations, Reporting_TemplateError: null }))
        }

        return isValid
    }


    useEffect(() => {
        Validation();
    }, [])

    const handleButtonclick = () => {
        const Validate = Validation();
        if (Validate) {
            setValidateCondition(false);
            sessionStorage.setItem("Step_02", JSON.stringify(formData_02))
            sessionStorage.setItem("Reporting_Template", JSON.stringify(formData_02.Reporting_Template))
            navigate('/select-approval');
        } else {
            setValidateCondition(true);
        }
    }


    const Reporting_Template = JSON.parse(sessionStorage.getItem("Reporting_Template"));


    return (
        <div className='onboarding-container'>
            <img src="logo.svg" alt='logo' />
            <div className='onboarding-content'>
                <div className='Onboarding-Heading'>Create a Reporting Year</div>
                <div className='Onboarding-Body'>Create a reporting year to begin your ESG reporting.</div>
                <div className='onboarding-form'>
                    <TextInput
                        id="year"
                        defaultValue={formData_02.Reporting_Year}
                        invalid={ValidateCondition && !formData_02.Reporting_Year}
                        invalidText={Validations.Reporting_YearError}
                        onChange={(e) => { setFormData_02({ ...formData_02, Reporting_Year: e.target.value }) }}
                        type="text"
                        labelText="Name of the Reporting Year" />
                    <DatePicker

                        value={formData_02.Date}
                        onChange={(e) => setFormData_02({ ...formData_02, Date: e.toLocaleString() })}
                         datePickerType="single" className='Date-Onboarding col-12'>
                        <DatePickerInput
                            labelText="Start Date"
                            placeholder="dd/mm/yyyy"
                            className='Date-Onboarding'
                            invalid={ValidateCondition && !formData_02.Date}
                            invalidText={Validations.DateError}
                            className='col-12'
                            style={{ width: "100%" }}
                            onChange={(e) => setFormData_02({ ...formData_02, Date: e.target.value })}
                        />
                    </DatePicker>
                    <Dropdown
                        id="country"
                        titleText="Reporting Template"
                        invalid={ValidateCondition && !formData_02.Reporting_Template}
                        invalidText={Validations.Reporting_TemplateError}
                        items={reporting_template}
                        initialSelectedItem={Reporting_Template&&Reporting_Template}
                        onChange={(Reporting_Template) => setFormData_02({ ...formData_02, Reporting_Template: Reporting_Template.selectedItem })}
                    />

                </div>
                <Button className='onboarding-button' onClick={handleButtonclick} renderIcon={ArrowRight}>Create a Reporting Year</Button>
            </div>
        </div>
    );
};

export default CreateReportingYear;
