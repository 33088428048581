import { Accordion, AccordionItem, Table, TableRow, TableHead, TableHeader, TableBody, TableCell } from '@carbon/react';

import React, { useEffect, useState } from 'react';
import { StringLimit, doPluralize, getAlldata } from '../../../APIController/APIControllers';
import { useNavigate } from 'react-router-dom';
import pluralize from "pluralize";

const Governance = () => {

    const [GovernanceMatrics, setGovernanceMatrics] = useState(null);

    const navigate = useNavigate();

    const HandleToNavigate = (data) => {
        // const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-')
        const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/[\s_]+/g, '-').replace(/-+/g, '-').trim();
        const PluralizedData = pluralize.singular(URLPath);
        console.log("PluralizedData", PluralizedData)
        const Singularized = PluralizedData.split("-")
            .map(word => word && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        const Navigator = Singularized.toLowerCase();
        navigate(`${Navigator}`)
    }

    useEffect(() => {
        getAlldata("governance-metrics")
            .then((data) => {
                setGovernanceMatrics(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    console.log("governance matrics", GovernanceMatrics)

    const headers = ["Source", "Description", "View Data"];

    return (
        <div className='Container-container'>
            <h1 className='Heading'>Governance</h1>
            <div className='PageDescription'>
                Governance Metrics are analyzed across multiple categories:{" "}
                <span className='BoldDescription'>Ethical Behavior</span>, {" "}
                <span className='BoldDescription'>Governing Purpose</span>, {" "}
                <span className='BoldDescription'>Quality of Governing Body</span>, {" "}
                <span className='BoldDescription'>Risk and Opportunity Oversight</span>, {" "} & {" "}
                <span className='BoldDescription'>Stakeholder Engagement</span>.



                <div className='AccordianClass'>

                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Anti-Corruption'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Anti-Corruption") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>

                        </AccordionItem>
                    </Accordion>


                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Board Diversity'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Board Diversity") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                   
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>

                        </AccordionItem>
                    </Accordion>



                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Certifications'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Certifications") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                  
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </AccordionItem>
                    </Accordion>



                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Invests in Innovation'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Invests in Innovation") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                  
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </AccordionItem>
                    </Accordion>

                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Corporate Tax'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Corporate Tax") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                   
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </AccordionItem>
                    </Accordion>

                    <Accordion size='lg' isflust={true}>
                        <AccordionItem title='Lobbying'>
                            <div className='TableAccordian'>
                                <Table aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(header => <TableHeader id={header.key} key={header}>
                                                {header}
                                            </TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GovernanceMatrics && GovernanceMatrics.map((item) => {
                                            if (item && item.attributes.Type === "Lobbying") {
                                                return <TableRow key={item.id}>
                                                    <TableCell>{item.attributes.Name}</TableCell>
                                                    <TableCell>{item&&item.attributes&& item.attributes.Description&&StringLimit( item.attributes.Description,80)}</TableCell>
                                                    <TableCell>
                                                        <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                            View Source
                                                        </div></TableCell>
                                                   
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default Governance;