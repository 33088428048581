
import React, { useState } from 'react';
import Loading from '../../MinorComponents/Loading/Loader';

const Dashboard = () => {

    const URL = "https://us-east-metabase.nanoheal.app/public/dashboard/ea7662c0-43cf-4120-8319-b1f52a6096fb/#theme=transparent&bordered=false&titled=false";

    const [Loader, setLoader] = useState(true);

    setTimeout(() => {
        setLoader(false)
    }, 5000)

    const UserData=JSON.parse(sessionStorage.getItem("userData"));
    return (
        <div className='Container-container' style={{height:'100%'}}>
            <h1 className='Dashboard-Heading'>Dashboard</h1>
            <p>Hey, {UserData&&UserData.user.email}</p>
                <div className='PageDescription'>
                    Connecting to reference data helps provide relevant, reliable calculations accross all ESG metrics.
                </div>
            <div className='cds--content IframeCustom'>
            {Loader &&<Loading /> }
            <iframe
                src={URL}
                frameBorder="0"
                width="100%"
                height="100%"
                allowTransparency
            />
            </div>
        </div>
    );
};

export default Dashboard;