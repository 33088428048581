import { Content, FormLabel, TextInput, MenuItemDivider, Button } from '@carbon/react';

import React, { useEffect, useState } from 'react'

const Profile = () => {

  const UserData = JSON.parse(sessionStorage.getItem('userData'));

  return (
    <div className='Profile-Container'>
      <div>
        <h3 className="bx--outline">Profile</h3>
        <br />
        <div className='bx--card' tabindex='0' aria-labelledby='#card-title'>
          <h4 >Your email</h4>
          <MenuItemDivider />
          <div className='Email--address' style={{ minWidth: 300, maxWidth:500 }}>
            <TextInput
              type="text"
              playgroundWidth={300}
              size="md"
              value={UserData && UserData.user.email}
              labelText='Email address' />
          </div>
          <MenuItemDivider />
          <div className='Update--email'>
            <Button size='sm'>Update email</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;