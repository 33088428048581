import { UpdateOneUserData } from "./APIControllers";

export const LoginUserGrabJwt = async (email, password) => {

    let mypayload = {
        "identifier": email,
        "password": password
    }
    const response = await fetch(
        `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/?populate=*`,
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(mypayload), // body data type must match "Content-Type" header
        }
    );

    let data = await response.json();
    console.log("logged in")
    sessionStorage.setItem("userData", JSON.stringify(data));
   
    return data;
}


export const RegisterUserGrabJWT = async (user_email, password, id) => {
    const payload = {
        email: user_email,
        password: password,
        username: user_email,

        role: {
            disconnect: [{id:18}],
            connect: [{
                id: id,
                position: {
                    end: true
                }
            }]
        }
    }

    const roleUpdate = {
        role: {
            "disconnect": [],
            "connect": [{
                "id": id,
                "position": {
                    "end": true}
            }]
        }
}
    const response = await fetch(
        `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/register?populate=*`,
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        }
    );

    let data = await response.json();
    let RegisteredResponse;
    if(data.user){
        RegisteredResponse= await UpdateOneUserData("users", data.user.id, roleUpdate);
        
    }

    return RegisteredResponse
}



export const AdminOnboardingRegister = async (payload) => {
    const response = await fetch(
        `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/auth/local/register?populate=*`,
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        }
    );

    let data = await response.json();
    console.log('%cAdminController.js line:109 data', 'color: #007acc;', data);
    console.log('%cAdminController.js line:109 data', 'color: #007acc;', data.user.password);

    if (data.data !== null) {
        LoginUserGrabJwt(data.user.email, payload.password)
    }
    // localStorage.setItem("Userdata",JSON.stringify(data))

}

