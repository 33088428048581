import React from 'react';

const Reports = () => {
    return (
        <div className='Container-container'>
            <h1 className='Dashboard-Heading'>
                Reports
            </h1>
        </div>
    );
};

export default Reports;