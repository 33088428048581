import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLoginJWT, LoginUserGrabJwt } from '../../APIController/AdministrationController';
import { Button, Header, HeaderName, TextInput } from '@carbon/react';
import LoginImage from "../../Images/ImageLogin.svg";
import { ArrowRight, Cloud, Email } from '@carbon/icons-react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Information } from '@carbon/icons-react';
import { GoogleAuth, getGoogleInfo } from '../SupertokenController';
import { UpdateOneData, UpdateOneUserData } from '../../APIController/APIControllers';
const SignIn = () => {
    const navigate = useNavigate();

    const handleOnNavigate = () => {
        navigate('/signup')
    }

    const handleNavigatePasswordless = () => {
        navigate('/passwordless-sign-in')
    }

    const handleGoogleAuth = async (e) => {
        e.stopPropagation()
        const data = await GoogleAuth();
        console.log("url",data.urlWithQueryParams);
        window.location.href = data.urlWithQueryParams;
    }


    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Sign in to Audyt ESG</div>
                <div className="Onboarding-Body">
                    Welcome to Audyt, our mission is to transform companies
                    with precision analytics and AI for a sustainable future.
                </div>
                <Button className='primary-login-button' onClick={handleNavigatePasswordless} renderIcon={Email}>
                    Continue with email
                </Button>
                <Button className='secondary-login-button' onClick={handleGoogleAuth} renderIcon={Cloud}>
                    Continue with Google

                </Button>
                <div className='helper-container'>
                    <Information className='icon' />
                    We recommend signing in with your work email
                </div>
                <div className='Terms'>
                    By continuing, you acknowledge that you have read and understood,
                    and agree to Audyt’s  <span className='bold-link'>Terms & Conditions</span> and <span className='bold-link'>
                        Privacy Policy.</span>
                </div>
            </div>
        </div>
    )
}

export default SignIn