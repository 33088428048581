import { Column, Row } from '@carbon/react';
import React from 'react'

const CompanyProfile = () => {
  return (
    <div className='Container-container'>
    <h1 >Organisation Profile</h1>
    <p className='Title-Section'>Basic Information</p>
    <Row>
        <Column>
        
        </Column>
    </Row>
    </div>
  )
}

export default CompanyProfile;