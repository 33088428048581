import { Table, TableRow, TableHead, TableHeader, TableBody, TableCell, SkeletonText, Dropdown } from '@carbon/react'
import { React, useEffect, useState } from 'react'
import { StringLimit, doPluralize, getAlldata } from '../../APIController/APIControllers';
import { Navigate, useNavigate } from 'react-router-dom';

const ReferenceData = () => {
    const [referenceData, setReferenceData] = useState(null);
    const [isOnline, setIsOnline] = useState(true);
    const [NullData, setNullData] = useState(false);
    const [Forbidden, setForbidden] = useState(false);
    const [CountDataState,setCountDataState] = useState(true);
    const [ReferenceFullData, setReferenceFullData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsOnline(true);
        getAlldata('references')
            .then((data) => {
                console.log("data", data)
                if (data && data.error && data.error.status === 404) {
                    setForbidden(true);
                    setIsOnline(false);
                    setNullData(false)
                } else if (data && data.data.length > 0) {
                    setReferenceData(data.data);
                    setForbidden(false);
                    setIsOnline(false);
                    setNullData(false);
                } else if (data && data.data.length === 0) {
                    setNullData(true);
                    setForbidden(false);
                    setIsOnline(false);
                } 
            })
            .catch((err) => {
                console.log(err)
            })


    }, [])



    useEffect(() => {
        setCountDataState(true)
        if (referenceData && referenceData.length > 0) {
            setIsOnline(true);
            const promises = referenceData.map((data) => {
                const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
                const PluralizedData = URLPath && doPluralize(URLPath);
                const Singularized = PluralizedData && PluralizedData.split("-")
                    .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                const promise2 = getAlldata(Singularized).then((data) => data).catch((err) => {
                    console.log(err);
                    return null;
                });
                return Promise.all([promise2]);
            });
            Promise.all(promises).then((data) => {

                const infoArray = data.map(([ReferenceData]) => ({ ReferenceData }));
                setReferenceFullData(infoArray);
                setCountDataState(false)
                setIsOnline(false);
            });
        }
    }, [referenceData]);

    console.log("isOnline", isOnline)
    const headers = ["Reference Source", "Description", "Data", "Total Data Points"];

    const HandleToNavigate = (data) => {
        const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
        const PluralizedData = URLPath && doPluralize(URLPath);
        const Singularized = PluralizedData && PluralizedData.split("-")
            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        navigate(`${Singularized}`)
    }


    return (

        <div className='Container-container'>
            {Forbidden ? <h1>404 page not found</h1> : <>

                <h1 className='Dashboard-Heading'>Reference Data</h1>
                <div className='PageDescription'>
                    Connecting to reference data helps provide relevant, reliable calculations accross all ESG metrics.
                </div>

                {NullData ? <h1>No data</h1> :
                    <>{isOnline === false&&CountDataState===false &&
                        <div className='DataTable'>
                            <Table size="md" useZebraStyles={false} aria-label="sample table" experimentalAutoAlign={true}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map(header => <TableHeader id={header.key} key={header}>
                                            {header}
                                        </TableHeader>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {referenceData && referenceData.map((item, index) => (
                                        <TableRow key={item.id} size="tall">
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            {/* Leave the other columns blank */}
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 120)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {ReferenceFullData[index] && ReferenceFullData[index].ReferenceData.meta ? String(ReferenceFullData[index].ReferenceData.meta.pagination.total).padStart(2, '0') : String(0).padStart(2, 0)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    }
                    </>
                }
            </>}
            {isOnline&&CountDataState&& <Table aria-label="sample table">
                <TableHead>
                    <TableRow>
                        {[1, 2, 3, 4, 5].map((data, index) => (
                            <TableHeader>
                                <SkeletonText />
                            </TableHeader>
                        ))}
                    </TableRow>

                </TableHead>
                <TableBody>
                    {[1, 2, 3].map((item, index) => (
                        <TableRow>
                            {[1, 2, 3, 4, 5].map((data, index) => (
                                <TableCell>
                                    <SkeletonText />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>}
        </div>
    )
}

export default ReferenceData