import { Button, DatePicker, DatePickerInput, Dropdown, FileUploader, FileUploaderItem, NumberInput, Select, SelectItem, TextArea, TextInput, Toggle } from '@carbon/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PublishData, UserAdminTesting, createPayload, doPluralize, fetchCollectionJson, getAlldata, getOnedata, handlechange } from '../../APIController/APIControllers';
import pluralize from "pluralize";
import { Add, TrashCan } from '@carbon/react/icons';
import { Calculator, Download } from '@carbon/icons-react';
import Loading from '../../MinorComponents/Loading/Loader';

const ReadData = () => {

    const params = useParams();
    const [collectionInfos, setCollectionInfos] = useState(null);
    const CollectionID = params._Id;
    const [CollectedData, setCollectedData] = useState(null);
    const [FetchRelationData, setFetchrelationData] = useState(null);
    const [elements, setElements] = useState([]);
    const [UserData, setUserData] = useState(null);
    const [Loader,setLoading]=useState(true);
    const [SelectedCalc, setSelectedcalc] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const [TenantData, setTenantData] = useState(null);
    const [Sidepanel, setSidePanel] = useState(true);
    const singularTerm = pluralize.singular(params._pathId);
    const Singularize = pluralize.singular(params._pathId);
    const [CollectionNameInfo, setCollectionNameInfo] = useState(null);
    const CollectionName = params._pathId?.replaceAll("-", ' ').split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const URL = window.location.href.split("/");
    const CollectionData = params._pathId
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"))

    useEffect(() => {
        const testAsync = async () => {
            let newJson = await fetchCollectionJson(Singularize);
            setElements(newJson);
        };
        testAsync();
    }, [Singularize]);

    useEffect(() => {
        getOnedata(CollectionData, CollectionID).then((data) => {
            setCollectedData(data);

        }).catch((err) => {
            console.log(err);
        });
    }, [CollectionData, CollectionID]);


    useEffect(() => {

        getAlldata('references').then((data) => {
            setCollectionInfos(data.data)
        })
            .catch((err) => { console.log(err) })

        getAlldata('tenant')
            .then((data) => { setTenantData(data.data) })
            .catch((err) => { console.log(err) })

        UserAdminTesting(UserDetails.user.id)
            .then((data) => { setUserData(data) })
            .catch((Err) => console.log(Err))

    }, [CollectionName, CollectionID])

    const DescriptionData = collectionInfos && collectionInfos.filter((data) => {
        const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
        const PluralizedData = URLPath && doPluralize(URLPath);
        const Singularized = PluralizedData && PluralizedData.split("-")
            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        return Singularized === params._pathId
    })


    setTimeout(()=>{
        setLoading(false);
    },2000)


    console.log("elements", elements)

    // Function to get data type Relation Json 
    useEffect(() => {
        let combinedData = [];
        elements && elements.map((data) => {
            if (data[1].type === "relation") {
                const RelationCollectionName = data && doPluralize(data[0].replaceAll("_", "-"))
                getAlldata(RelationCollectionName).then((data) => {
                    const transformedData = data && data.data !== null ? data.data.map((item) => {
                        return {
                            id: item.id,
                            attributes: {
                                collectionName: RelationCollectionName,
                                ...item.attributes
                            }
                        }
                    }) : [{ attributes: { collectionName: RelationCollectionName } }];
                    combinedData = combinedData.concat(transformedData);
                    setFetchrelationData(combinedData);
                })
            }
        })
    }, [elements])


    // Function to fill values in the array based on the data object
    function fillValues(array, data) {
        array.forEach((field, index) => {
            const fieldName = field[0];
            const fieldType = field[1].type;
            const fieldValue = data && data.data && data.data.attributes[fieldName];

            // Check if the field exists in the data object
            if (fieldValue !== undefined) {
                // Update the value in the array
                field[field.length - 1].value = fieldValue;
            }
        });
    }

    const handleChangeEvent = async (e, i, elements) => {
        let ChangedData = await handlechange(e, i, elements);
        setElements(ChangedData);
        return ChangedData;
    };


    useEffect(() => {
        // Call the function to fill values
        fillValues(elements, CollectedData);
    }, [CollectedData])


    const downloadHandler = (URL) => {
        const anchor = document.createElement('a');
        anchor.href = `${process.env.REACT_APP_STRAPI_URL}/${URL}`;
        anchor.download = URL;
        anchor.click();
    }


    // Filter function to remove "dumper" string
    const filteredData = elements && elements.filter(([name]) => !name.startsWith("dumper"));


    // Grid for arranaging create for 
    const rows = []
    if (filteredData && filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i += 8) {
            rows.push(filteredData.slice(i, i + 8));
        }
    }


    const [validationErrors, setValidationErrors] = useState({});
    const [LinkURl, setLinkURl] = useState(null);
    const UrlPatternRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    var letters = /^[A-Za-z]+$/;
    var numberRegex = /^[0-9]*$/;


    const ValidationForm = () => {
        let isValid = true;
        const errors = {};

        elements.forEach(([fieldName, fieldProps, fieldId, fieldValues]) => {

            const fieldType = fieldProps.type;
            const fieldValue = fieldValues.value;
            const isRequired = fieldProps && fieldProps.required
            // You can add more validation rules based on the field type
            if (fieldType === "text" && fieldName === "Name" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }

            if (fieldType === "string" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }

            if (fieldType === "enumeration" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }


            if (isRequired && (fieldType === "integer" || fieldType == 'biginteger' || fieldType === 'decimal' || fieldType === 'float')) {
                if (!numberRegex.test(fieldValue)) {
                    errors[fieldName] = `${fieldName} accept only numbers`;
                    isValid = false;
                }
            }


            if (isRequired && fieldType === "media") {
                if (fieldValue === "") {
                    errors[fieldName] = `Upload a file `;
                    isValid = false;
                }
            }

            if (isRequired && fieldType === "relation") {
                if (fieldValue === "") {
                    errors[fieldName] = `Select the ${fieldName} ralated`
                    isValid = false;
                }
            }

            if (isRequired && fieldType === "datetime") {
                // Check if the datetime is in the future
                const currentDate = new Date();
                const fieldDate = new Date(fieldValue);

                if (fieldDate <= currentDate) {
                    errors[fieldName] = `${fieldName} must be in the future`;
                    isValid = false;
                }
            }

            if (isRequired && fieldType === "richtext") {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required `;
                    isValid = false;
                }
            }
        });
        setValidationErrors(errors);
        return isValid;
    };

    const TestcalculateHandler = async (e) => {
        setSidePanel(!Sidepanel)
        let payload = await createPayload(elements);
        setSelectedcalc(payload)
    }

    const Required = (FieldName) => {
        return <>{FieldName}<span style={{ color: 'red' }}>*</span></>
    }

    const approval_list = ["No approval flow", "One Step Approval", "Two Step Approval"];


    const Navigate = useNavigate();

    const submitHandler = async (e) => {
        const Validate = ValidationForm();
        console.log("Validate", Validate);
        if (Validate) {
            e.preventDefault();
            let payload = await createPayload(elements);
            let result = await PublishData(payload, params._pathId);
            const DoPluralization = params._id && doPluralize(params._id.charAt(0).toUpperCase() + params._id.slice(1))
            window.location.reload();
            Navigate(-1);
        } else {
            console.log("Some validation issue.")
        }
    };

    return (
        <div className='Container-container'>
            <h1>{CollectionName}</h1>
            <p className='Collection-Info'>{DescriptionData && DescriptionData[0] && DescriptionData[0].attributes.Description}</p>
            <div className='Create-Container'>
                <div className="stack-Component">
                        {Loader?<Loading/>:
                    <div className="stack-Component">
                        {rows &&
                            rows.map((element, i) => (
                                <div className='Row-Container'>
                                    {element && element.map((data, i) => {
                                        const fieldError = validationErrors[data[0]];
                                        if (data[1].type === "string") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            return <TextInput
                                                className='cds--file--label'
                                                id="text-input-1"
                                                type="text"
                                                value={data[3].value}
                                                placeholder={`Enter ${data[0]}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "text") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            return <TextInput
                                                value={data[3].value}
                                                id="text-area-1"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }

                                        if (data[1].type === "richtext") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            return <TextInput
                                                value={data[3].value}
                                                id="text-area-1"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "biginteger" || data[1].type === "integer" || data[1].type === "decimal" || data[1].type === "float") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;

                                            return <TextInput
                                                value={data[3].value}
                                                id="text-area-1"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "enumeration") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            const DefaultValue=data[3].value
                                            
                                            return <>{DefaultValue&&<Dropdown
                                            titleText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                            initialSelectedItem={DefaultValue}
                                            items={data[1].enum}
                                            onChange={(e) => {
                                                handleChangeEvent(e.selectedItem, i, elements)
                                            }}
                                        />}</>
                                        }
                                        if (data[1].type === "relation") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const CollectionApiName = data[0] && data[0].replaceAll("_", "-").toLowerCase();
                                            const pluralizedCollectionApiName = CollectionApiName && doPluralize(CollectionApiName);
                                            const RelationData = data && data[3].value && data[3].value.data !== null ? data[3].value.data[0] : "";
                                            const RelatedValue = RelationData && RelationData !== null ? RelationData.attributes.Name : "Open to select";
                                            const RelatedID = RelationData && RelationData !== null ? RelationData.id : "";
                                            const DefaultValue = data[3] && data[3].value&&data[3].value.data;
                                         
                                            // DefaultValue&&console.log("DefaultValue.attributes.Name",DefaultValue.attributes.Name)
                                            return <>{(DefaultValue===null||DefaultValue!==null) && <Dropdown
                                                id={`select-${i}`}
                                                key={`dropdown-${i}`} 
                                                onChange={(e) => handleChangeEvent(e, i, elements)}
                                                titleText={FieldName}
                                                label={DefaultValue&&DefaultValue.attributes.Name}
                                                items={[
                                                    { id: '', value: 'Open to select' },
                                                    ...(FetchRelationData
                                                        ? FetchRelationData
                                                            .filter(item =>
                                                                pluralizedCollectionApiName &&
                                                                item.attributes.collectionName &&
                                                                pluralizedCollectionApiName ===
                                                                item.attributes.collectionName
                                                                    .replaceAll("_", "-")
                                                                    .toLowerCase()
                                                            )
                                                            .map((item, index) => ({
                                                                id: item.id,
                                                                value: item.attributes.Name
                                                            }))
                                                        : [])
                                                ]}
                                                itemToString={item => item ? item.value : ''}
                                            />}</>
                                        }
                                        if (data[1].type === "boolean") {
                                            return <Toggle
                                                labelText={data[0]}
                                                toggled={data[3].value}
                                                id={`toggle-${i}`} />
                                        }
                                        if (data[1].type === "date") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ")
                                            return <DatePicker datePickerType="single">
                                                <DatePickerInput
                                                    value={data[3].value}
                                                    placeholder="mm/dd/yyyy"
                                                    labelText={FieldName}
                                                    onChange={(e) => handleChangeEvent(e, i, elements)} />
                                            </DatePicker>
                                        }
                                        if (data[1].type === "media" && data[1].multiple === false) {

                                            const FieldName = data[0] && data[0].replaceAll("_", " ")
                                            const MediaName = data[3].value && data[3].value.data && data[3].value.data.attributes.name;
                                            const ext = data[3].value && data[3].value.data && data[3].value.data.attributes.ext;
                                            const FileURl = data[3].value && data[3].value.data && data[3].value.data.attributes.url;
                                            return <div className="cds--file__container " >
                                                <div className='cds--text-input__label-wrapper'>
                                                    <label className="cds--label">{FieldName}</label>
                                                </div>
                                                {(ext !== ".pdf") ?
                                                    <>
                                                        <img
                                                            width={160}
                                                            height={160}
                                                            src={data && data[3].value !== null && data[3].value.data !== null ?
                                                                `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data[3].value.data.attributes.url}` : ""} />
                                                    </> : <div className='Container-file-upload-block'>
                                                        <FileUploaderItem
                                                            errorBody="500kb max file size. Select a new file and try again."
                                                            errorSubject="File size exceeds limit"
                                                            iconDescription="Delete file"
                                                            name={MediaName}
                                                            // onDelete=""
                                                            size="md"
                                                            status="edit"
                                                        />
                                                        <Download onClick={() => downloadHandler(FileURl)} />
                                                    </div>}
                                            </div>
                                        }
                                        if (data[1].type === "media" && data[1].multiple === true) {
                                            const FieldName = data[0] && data[0].replaceAll("_", "-")
                                            console.log('%cReadData.jsx line:177 data[3].value.data.attributes.url', 'color: #007acc;', data[3].value);
                                            return <div className="cds--file__container" >
                                                <div className='cds--text-input__label-wrapper'>
                                                    <label className="cds--label">{FieldName}</label>
                                                </div>
                                                <img
                                                    width={160}
                                                    height={160}
                                                    src={data && data[3].value !== null && data[3].value.data !== null ?
                                                        `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data[3].value.data.attributes.url}` : ""} />
                                            </div>
                                        }
                                    })}
                                </div>
                            ))}
                        <div>
                            {URL[3] === "reference-data" ? "" :
                                <>
                                    {/* <div>
                                        <Button className='CreatePost-Btn' renderIcon={Calculator} onClick={TestcalculateHandler}>Test calc</Button>
                                    </div> */}

                                    <br />
                                </>
                            }
                            <Button renderIcon={Add} iconDescription="Add" className='CreatePost-Btn' onClick={submitHandler}>Update</Button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ReadData;