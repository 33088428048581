import React, { useEffect, useState } from 'react';
import { GetAllMedia } from '../../APIController/APIControllers';
import { Table, TableRow, TableHead, TableHeader, TableBody, TableCell } from '@carbon/react'
import { Download } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';

const Evidence = () => {

    const [mediaData, setMediaData] = useState(null);
    const Navigate = useNavigate()
    useEffect(() => {
        GetAllMedia()
            .then((data) => {
                setMediaData(data)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    console.log(mediaData)
    const headers = ["Sl. No.", "Name", "Document Type", "Size", "Date", ""];

    const downloadHandler = (url) => {
        // Use the appropriate method to trigger the download
        // For example, you can use an anchor tag with the download attribute
        const anchor = document.createElement('a');
        anchor.href = `${process.env.REACT_APP_STRAPI_URL}/${url}`;
        anchor.download = url; // Set the desired filename
        anchor.click();
    };

    return (

        <div className='Container-container'>
            <h1 className='Heading'>Evidences</h1>
            <div className='PageDescription'>
                View your evidences that backs your ESG reports.
            </div>
            <div className='DataTable'>
                <Table size="md" useZebraStyles={false} aria-label="sample table" experimentalAutoAlign={true}>
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableHeader id={header.key} key={header}>
                                    {header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mediaData && mediaData.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.ext}</TableCell>
                                <TableCell>{item.size} KB</TableCell>
                                <TableCell>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    <div className="icondownload"onClick={() => downloadHandler(item.url)}>
                                        <Download />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default Evidence;