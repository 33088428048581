import React, { useEffect, useState } from 'react';
import { getAlldata } from '../../APIController/APIControllers';
import Loading from '../../MinorComponents/Loading/Loader';
import { useNavigate, useParams } from 'react-router-dom';

const InvitedUserOnboarding = (props) => {
  const [CrclInfo, setCrclInfo] = useState(null);
  const param= useParams()
  const userid=  param.id
  const [adminInfo, setAdminInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const UserName = JSON.parse(localStorage.getItem("userData"));
  const [showNotification, setShowNotification] = useState(true);
  const Navigate=useNavigate();

  const REACT_APP_ADMIN_PASSWORD ="Crcladmin@2023"
  const REACT_APP_ADMIN_EMAIL ="crcladmin@crclverse.com"
  setTimeout(() => {
    setShowNotification(false);
  }, 4000);

  useEffect(() => {
    getAlldata("users").then((data) => {
      setAdminInfo(data)
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(true)
    })
  }, [])

  console.log("User Data", adminInfo)

  const adminData = adminInfo && adminInfo.filter((item) => {
    if (item === null) {
      setIsLoading(true)
    } else if (item.email === REACT_APP_ADMIN_EMAIL) {
      return item;
    }
  })

  console.log("AdminData", adminData)

  useEffect(() => {
    setCrclInfo(props.OrganizationProfile)
  }, [props])

  const GetStartHandler=()=>{
    setIsLoading(true)

    setTimeout(()=>{
      Navigate(`/invitedOnboarding/${userid}`)
    },2000)
  }

  return (
    <div className='UserOnboarding'>
      {isLoading ?
        <div className='LoadingOver'>
          <Loading />
        </div>
        : ""}
      {isLoading ? "" :
        <div
          className={`UserOnboarding-Notification ${showNotification ? "animateOpen" : "animateClose"}`}
        >
          <img src="" width={40} height={40} alt='ico' />
          <div>
            <p className='NotificationHeading'>Hello there</p>
            <p className='NotificationDescription'>Your new account is ready</p>
          </div>
        </div>
      }
      <div className='UserOnboarding-Container'>
        <div className='UserOnboaring-Info'>
          <img width={40} height={40} src={`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${CrclInfo && CrclInfo.attributes.Crcl_Image.data ?
            CrclInfo && CrclInfo.attributes.Crcl_Image.data.attributes.url
            : "/uploads/Organization_Default_Img_b066801043.png?updated_at=2023-07-06T09:21:03.995Z"}`} alt="CRCLLogo" />
          <p className='OnboardingHeading'>Welcome to <span>{CrclInfo && CrclInfo.attributes.CRCL_Name}</span></p>
          <p>Dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <div className='OnboadingCard'>
          <div className='OnboadingCard-Section'>
            <p>Hello, our newest member!</p>
            <p>Minima repellendus autem iusto necessitatibus.</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem unde ratione dolor eveniet repellat? Rem eligendi pariatur vero libero quaerat quo modi mollitia maxime iste? Minima repellendus autem iusto necessitatibus.
            </p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem unde ratione dolor eveniet repellat? Rem eligendi pariatur vero libero quaerat quo modi mollitia maxime iste? Minima repellendus autem iusto necessitatibus.
            </p>
            <p>Minima repellendus autem iusto necessitatibus.</p>
            <div className='AdminDetails-Section'>
              {adminData && adminData.map((item, index) => {
                if (item === null) {
                  setIsLoading(true)
                }
                return <>
                  <div>
                    <img className='OnboradingAdminImg' src={`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${item && item.Profilepicture !== null ? item && item.Profilepicture.url : "/uploads/Default_Image_5cb581f088.webp?updated_at=2023-07-10T09:09:02.996Z"}`} alt='adminImg' />
                  </div>
                  <div className='Onboarding-UserInfo'>
                    <p>{item && item.FirstName} {" "} {item && item.LastName}</p>
                    <p>Admin</p>
                  </div>
                </>
              })}
            </div>
          </div>
          <div className='OnboardingSideImage'>
            <i className="fa-regular fa-envelope-open"></i>
          </div>
        </div>
        <button 
        onClick={GetStartHandler}
        className='Login-Btn'
        >
          Get started
        </button>
      </div>
    </div>
  );
}

export default InvitedUserOnboarding