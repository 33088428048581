import { Button, DatePicker, DatePickerInput, Dropdown, FileUploader, FileUploaderDropContainer, FormItem, Header, HeaderPanel, ListItem, NumberInput, Select, SelectItem, SideNav, SideNavItems, SideNavLink, TextArea, TextInput, Theme, Toggle } from '@carbon/react';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import pluralize from "pluralize"
import { Add, TrashCan } from '@carbon/react/icons';
import { Calculator, Close } from '@carbon/icons-react';
import { useLocation } from 'react-router-dom';
import { PublishData, UpdateSingleTypeData, UserAdminTesting, createPayload, doPluralize, fetchCollectionJson, getAlldata, getOnedata, handlechange, updateData } from '../../../APIController/APIControllers';

const ReadSingleTypeMatrics = () => {

    const params = useParams();
    const Navigate = useNavigate();
    const [elements, setElements] = useState([]);
    const [collectionInfos, setCollectionInfos] = useState(null);
    const [FetchRelationData, setFetchrelationData] = useState(null);
    const [Sidepanel, setSidePanel] = useState(false);
    const [SelectedCalc, setSelectedcalc] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const singularTerm = pluralize.singular(params._pathId);
    const Singularize = pluralize.singular(params._pathId);
    const [UserData, setUserData] = useState(null);
    const [TenantData, setTenantData] = useState(null);
    const collectionName = params._pathId?.replaceAll("-", ' ').split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const URL = window.location.href.split("/");
    const Location = useLocation();
    const URLDescription = Location && Location.pathname.split("/");
    const NewURLMatrics = URLDescription && URLDescription[1]
    console.log("NewURLMatrics", NewURLMatrics)
    const URLMatrics = `${NewURLMatrics}-metrics`
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"))

    console.log("URL", Location)
    useEffect(() => {
        const testAsync = async () => {
            let newJson = await fetchCollectionJson(Singularize);
            setElements(newJson);
        };
        testAsync();
    }, [Singularize]);

    useEffect(() => {
        getAlldata(URLMatrics).then((data) => {
            setCollectionInfos(data.data)
        })
            .catch((err) => { console.log(err) })

        getAlldata('tenant')
            .then((data) => { setTenantData(data.data) })
            .catch((err) => { console.log(err) })

        UserAdminTesting(UserDetails.user.id)
            .then((data) => { setUserData(data) })
            .catch((Err) => console.log(Err))

    }, [])


    const DescriptionData = collectionInfos && collectionInfos.filter((data) => {
        const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/[\s_]+/g, '-').replace(/-+/g, '-').trim();
        const PluralizedData = URLPath && pluralize.singular(URLPath);
        const Singularized = PluralizedData && PluralizedData.split("-")
            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        const Singular = Singularized && Singularized.toLowerCase();
        return Singular === params._pathId

    })


    const handleChangeEvent = async (e, i, elements) => {
        console.log("e", e)
        let ChangedData = await handlechange(e, i, elements);
        console.log("ChangedData", ChangedData)
        setElements(ChangedData);
        return ChangedData;
    };

    useEffect(() => {
        let combinedData = [];
        elements && elements.map((data) => {
            if (data[1].type === "relation") {
                const RelationCollectionName = data && doPluralize(data[0].replaceAll("_", "-")).toLowerCase()
                getAlldata(RelationCollectionName).then((data) => {
                    const transformedData = data && data.data !== null ? data.data.map((item) => {
                        return {
                            id: item.id,
                            attributes: {
                                collectionName: RelationCollectionName,
                                ...item.attributes
                            }
                        }
                    }) : [{ attributes: { collectionName: RelationCollectionName } }];
                    combinedData = combinedData.concat(transformedData);
                    setFetchrelationData(combinedData);
                })
            }
        })
    }, [elements])

    // Filter function to remove "dumper" string
    const filteredData = elements && elements.filter(([name]) => !name.startsWith("dumper"));


    const [CollectedData, setCollectedData] = useState(null);
    useEffect(() => {
        getAlldata(Singularize).then((data) => {
            setCollectedData(data);

        }).catch((err) => {
            console.log(err);
        });
    }, [Singularize]);

    console.log("CollectedData", CollectedData)

    // Grid for arranaging create for 
    const rows = []
    if (filteredData && filteredData.length > 0) {
        for (let i = 0; i < filteredData.length; i += 6) {
            rows.push(filteredData.slice(i, i + 6));
        }
    }

    // Function to fill values in the array based on the data object
    function fillValues(array, data) {
        array.forEach((field, index) => {
            const fieldName = field[0];
            const fieldType = field[1].type;
            const fieldValue = data && data.data && data.data.attributes[fieldName];

            // Check if the field exists in the data object
            if (fieldValue !== undefined) {
                // Update the value in the array
                field[field.length - 1].value = fieldValue;
            }
        });
    }


    useEffect(() => {
        // Call the function to fill values
        fillValues(elements, CollectedData);
    }, [CollectedData])

    console.log("elements", elements)


    const [validationErrors, setValidationErrors] = useState({});
    const [LinkURl, setLinkURl] = useState(null);
    const UrlPatternRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    var letters = /^[A-Za-z]+$/;
    var numberRegex = /^[0-9]*$/;


    const ValidationForm = () => {
        let isValid = true;
        const errors = {};

        elements.forEach(([fieldName, fieldProps, fieldId, fieldValues]) => {

            const fieldType = fieldProps.type;
            const fieldValue = fieldValues.value;
            const isRequired = fieldProps && fieldProps.required
            // You can add more validation rules based on the field type
            if (fieldType === "text" && fieldName === "Name" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }

            if (fieldType === "string" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }

            if (fieldType === "enumeration" && isRequired) {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required`;
                    isValid = false;
                }
            }


            if (isRequired && (fieldType === "integer" || fieldType == 'biginteger' || fieldType === 'decimal' || fieldType === 'float')) {
                if (!numberRegex.test(fieldValue)) {
                    errors[fieldName] = `${fieldName} accept only numbers`;
                    isValid = false;
                }
            }


            if (isRequired && fieldType === "media") {
                if (fieldValue === "") {
                    errors[fieldName] = `Upload a file `;
                    isValid = false;
                }
            }

            if (isRequired && fieldType === "relation") {
                if (fieldValue === "") {
                    errors[fieldName] = `Select the ${fieldName} ralated`
                    isValid = false;
                }
            }

            if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
                if (fieldValue === '') {
                    errors[fieldName] = `${fieldName} is required.`;
                    isValid = false;
                }
            }

            if (isRequired && fieldType === "richtext") {
                if (fieldValue === "") {
                    errors[fieldName] = `${fieldName} is required `;
                    isValid = false;
                }
            }
        });
        setValidationErrors(errors);
        return isValid;
    };


    const FetchRelationDataArray = (pluralizedCollectionApiName) => {
        const NewValue = FetchRelationData && FetchRelationData.filter(item =>
            pluralizedCollectionApiName &&
            item.attributes.collectionName &&
            pluralizedCollectionApiName ===
            item.attributes.collectionName
                .replaceAll("_", "-")
                .toLowerCase()
        )
            .map((item, index) => {
                return {
                    id: item.id,
                    value: item.attributes.Name
                }
            })
        return NewValue
    }

    console.log("elements", elements)

    const submitHandler = async (e) => {
        const Validate = ValidationForm();
        console.log("Validate", Validate);
        if (Validate) {
            e.preventDefault();
            let payload = await createPayload(elements);
            let result = await updateData(params._pathId, payload.data);
            console.log("result", result)
            const DoPluralization = params._id && doPluralize(params._id.charAt(0).toUpperCase() + params._id.slice(1))
            window.location.reload();
            Navigate(-1);
        } else {
            console.log("Some validation issue.")
        }
    };

    const TestcalculateHandler = async (e) => {
        setSidePanel(!Sidepanel)
        let payload = await createPayload(elements);
        setSelectedcalc(payload)
    }

    const Required = (FieldName) => {
        return <>{FieldName}<span style={{ color: 'red' }}>*</span></>
    }

    const approval_list = ["No approval flow", "One Step Approval", "Two Step Approval"];
    let continuousIndex = 0;

    return (
        <div className='Container-container'>
            <h1>{collectionName}</h1>
            <p className='Collection-Info'>{DescriptionData && DescriptionData[0] && DescriptionData[0].attributes.Description}</p>
            {/* {UserData && <>
                {TenantData && TenantData.attributes &&
                    <>
                        <TextInput
                            disabled

                            id={`select-1`}
                            titleText="Approval Process"
                            value={TenantData.attributes.Default_Approval}
                        />
                        <br />
                    </>}
            </>} */}
            <div className='Create-Container'>
                <div className="stack-Component">
                    {rows.length > 0 ? <>
                        {rows &&
                            rows.map((element, rowIndex) => (
                                <div className='Row-Container' key={`row-${rowIndex}`}>
                                    {element && element.map((data, columnindex) => {
                                        const i = continuousIndex++;
                                        const fieldError = validationErrors[data[0]];
                                        if (data[1].type === "string") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ")
                                            const isRequired = data[1] && data[1].required;
                                            return <TextInput
                                            key={`text-input-${i}`}
                                                required
                                                className='cds--file--label'
                                                id="text-input-1"
                                                type="text"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "text") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;

                                            return <TextInput
                                                className='cds--file--label'
                                                id="text-input-1"
                                                type="text"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "richtext") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            return <TextArea
                                                required={true}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                rows={4}
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                id="text-area-1"
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError} />
                                        }
                                        if (data[1].type === "biginteger" || data[1].type === "integer" || data[1].type === "decimal" || data[1].type === "float") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;

                                            return <TextInput
                                                value={data[3].value}
                                                type='number'
                                                id="text-area-1"
                                                onChange={(e) =>
                                                    handleChangeEvent(e, i, elements)}
                                                placeholder={`Enter ${FieldName}`}
                                                labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                            />
                                        }
                                        if (data[1].type === "enumeration") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            console.log("data",data[3])
                                            // return <Select
                                            //     id={`select-1`}
                                            //     onChange={(e) =>
                                            //         handleChangeEvent(e, i, elements)
                                            //     }
                                            //     labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}>
                                            //     <SelectItem value="" text="Open to select" />
                                            //     {data[1].enum.map((data) => (
                                            //         <SelectItem value={data} text={data} />
                                            //     ))}
                                            // </Select>
                                            return <Dropdown
                                                titleText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                items={data[1].enum}
                                                onChange={(e) => handleChangeEvent(e.selectedItem, i, elements)}
                                            />
                                        }

                                        if (data[1].type === "relation") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const CollectionApiName = data[0] && data[0].replaceAll("_", "-").toLowerCase();
                                            const pluralizedCollectionApiName = CollectionApiName && doPluralize(CollectionApiName);
                                            const DefaultValue = data[3] && data[3].value && data[3].value.data && data[3].value.data[0] && data[3].value.data[0];
                                            return <>{DefaultValue && <Dropdown
                                                id={`select-${i}`}
                                                key={`dropdown-${i}`} 
                                                onChange={(e) => handleChangeEvent(e, i, elements)}
                                                titleText={FieldName}
                                                label={DefaultValue&&DefaultValue.attributes.Name}
                                                items={[
                                                    { id: '', value: 'Open to select' },
                                                    ...(FetchRelationData
                                                        ? FetchRelationData
                                                            .filter(item =>
                                                                pluralizedCollectionApiName &&
                                                                item.attributes.collectionName &&
                                                                pluralizedCollectionApiName ===
                                                                item.attributes.collectionName
                                                                    .replaceAll("_", "-")
                                                                    .toLowerCase()
                                                            )
                                                            .map((item, index) => ({
                                                                id: item.id,
                                                                value: item.attributes.Name
                                                            }))
                                                        : [])
                                                ]}
                                                itemToString={item => item ? item.value : ''}
                                            />}</>
                                        }
                                        if (data[1].type === "boolean") {
                                            return <Toggle
                                                labelText={data[0]}
                                                onToggle={(e) =>
                                                    handleChangeEvent(e, i, elements)
                                                }
                                                checked={elements[i][0]}
                                                id={`toggle-${i}`} />
                                        }
                                        if (data[1].type === "datetime" || data[1].type === "date") {
                                            const FieldName = data[0] && data[0].replaceAll("_", " ");
                                            const isRequired = data[1] && data[1].required;
                                            return <DatePicker
                                                datePickerType="single"
                                                dateFormat='y-m-d'
                                                onChange={(e) =>  handleChangeEvent(e, i, elements)}
                                                 >
                                                <DatePickerInput
                                                    placeholder="mm/dd/yyyy"
                                                    labelText={<>{isRequired ? Required(FieldName) : FieldName}</>}
                                                    invalid={fieldError && isRequired}
                                                invalidText={fieldError}
                                                />
                                            </DatePicker>
                                        }

                                    })}
                                </div>

                            ))}
                        <div className='CreatePost'>
                            <div className="Grid-Component">
                                {rows &&
                                    rows.map((element, i) => (
                                        <div className='Row-Container'>
                                            {element && element.map((data, i) => {
                                                const fieldError = validationErrors[data[0]];
                                                if (data[1].type === "media" && data[1].multiple === false) {
                                                    const FieldName = data[0] && data[0].replaceAll("_", " ")
                                                    const isRequired = data[1] && data[1].required;
                                                    return <div className="cds--file__container CreatePost" >
                                                        <FileUploader
                                                            labelTitle={FieldName}
                                                            labelDescription="Max file size is 500mb. Only .jpg files are supported."
                                                            buttonLabel="Add file"
                                                            size="md"
                                                            filenameStatus="edit"
                                                            multiple={false}
                                                            disabled={false}
                                                            iconDescription="Delete file"
                                                            onChange={(e) => {
                                                                handleChangeEvent(e, i, elements);
                                                                setSelectedImage(e.target.value);
                                                            }}
                                                            invalid={fieldError && isRequired}
                                                            invalidText={fieldError}
                                                            name="" />
                                                    </div>
                                                }
                                                if (data[1].type === "media" && data[1].multiple === true) {
                                                    const isRequired = data[1] && data[1].required;
                                                    const FieldName = data[0] && data[0].replaceAll("_", "-")
                                                    return <div className="cds--file__container CreatePost">
                                                        <FileUploader
                                                            invalid={fieldError && isRequired}
                                                            invalidText={fieldError}
                                                            labelTitle={FieldName}
                                                            labelDescription="Max file size is 500mb. Only .jpg files are supported."
                                                            buttonLabel="Add file"
                                                            buttonKind="primary"
                                                            size="md"
                                                            filenameStatus="edit"
                                                            multiple={true}
                                                            disabled={false}
                                                            iconDescription="Delete file"
                                                            onChange={(e) => {
                                                                handleChangeEvent(e, i, elements);
                                                                setSelectedImage(e.target.value);
                                                            }}
                                                            name="" />
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    ))}
                            </div>
                            {URL[3] === "reference-data" ? "" :
                                <>
                                    <div>
                                        <Button className='CreatePost-Btn' renderIcon={Calculator} onClick={TestcalculateHandler}>Test calc</Button>
                                    </div>
                                    <br />
                                </>
                            }
                            <Button renderIcon={Add} iconDescription="Add" className='CreatePost-Btn' onClick={submitHandler}>Create</Button>
                        </div>
                    </> : <h1></h1>}
                    <Header>
                        <HeaderPanel expanded={Sidepanel} style={Sidepanel ? { inlineSize: "36rem" } : {}}>
                            <div className="Overlay-Component" >
                                <div style={{ textAlign: "end" }}>
                                    <Close size='24' onClick={() => setSidePanel(false)} />
                                </div>
                                <div>
                                    {JSON.stringify(SelectedCalc)}
                                </div>
                            </div>
                        </HeaderPanel>
                    </Header>
                </div>
            </div>
        </div>
    )
}

export default ReadSingleTypeMatrics;