import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLoginJWT, LoginUserGrabJwt } from '../../APIController/AdministrationController';
import { Button, Header, HeaderName, TextInput } from '@carbon/react';
import { ArrowRight, Cloud, Email } from '@carbon/icons-react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Information } from '@carbon/icons-react';

const Signup = () => {
    const navigate = useNavigate();
    const handleOnNavigate = () => {
        navigate('/login')
    }
    const handleNavigatePasswordless = () => {
        navigate('/passwordless-signup')

    }

    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Signup for Audyt ESG</div>
                <div className="Onboarding-Body">
                    Welcome to Audyt, our mission is to transform companies
                    with precision analytics and AI for a sustainable future.
                </div>
                <Button className='primary-login-button' onClick={handleNavigatePasswordless} renderIcon={Email}>
                    Continue with email
                </Button>
                <Button className='secondary-login-button' renderIcon={Cloud}>
                    Continue with Google

                </Button>
                <div className='helper-container'>
                    <Information className='icon' />
                    We recommend using your work email
                </div>
                <div className='Terms'>
                    By continuing, you acknowledge that you have read and understood,
                    and agree to Audyt’s  <span className='bold-link'>Terms & Conditions</span> and <span className='bold-link'>
                        Privacy Policy.</span>
                </div>

                <div className='Signup-container'>
                    Already have an account? &nbsp;
                    <div className='bold-link' onClick={handleOnNavigate}> {" "} Login {">"}</div>
                </div>
            </div>
        </div>
    )
};

export default Signup;