
import { Accordion, AccordionItem, Table, TableRow, TableHead, TableHeader, TableBody, TableCell } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { GetFilteredData, doPluralize, getAlldata } from '../../../APIController/APIControllers';
import { Navigate, useNavigate } from 'react-router-dom';

const Environment = () => {
    const [EnvironmentData, setEnvironmentData] = useState([]);
    const [ReferenceFullData, setReferenceFullData] = useState([]);
    const [CountDataState, setCountDataState] = useState(true);
    const [isOnline, setIsOnline] = useState(true);
    const navigate = useNavigate();


    const HandleToNavigate = (data) => {
        const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-')
        const PluralizedData = doPluralize(URLPath);
        const Singularized = PluralizedData.split("-")
            .map(word => word && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        navigate(`${Singularized}`)
    }
    // Scope 1
    useEffect(() => {

        getAlldata("environment-metrics")
            .then((data) => { setEnvironmentData(data.data) })
            .catch((err) => console.log(err))

    }, [])

 

    useEffect(() => {
        setCountDataState(true)
        if (EnvironmentData && EnvironmentData.length > 0) {
            setIsOnline(true);
            const promises = EnvironmentData.map((data) => {
                const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
                const PluralizedData = URLPath && doPluralize(URLPath);
                const Singularized = PluralizedData && PluralizedData.split("-")
                    .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                const promise2 = getAlldata(Singularized).then((data) => { return { Singularized, data } }).catch((err) => {
                    console.log(err);
                    return null;
                });
               
                return Promise.all([promise2]);
            });
            Promise.all(promises).then((data) => {

                const infoArray = data.map(([data]) => ({ data }));
                setReferenceFullData(infoArray);
                setCountDataState(false)
                setIsOnline(false);
            });
        }
    }, [EnvironmentData]);


    const FilteredCount = (ReferenceItem) => {

        const Filter = ReferenceFullData && ReferenceFullData.filter((data) =>
            data.data.Singularized === ReferenceItem
        )

        Filter&& Filter[0]&& console.log("Filter[0].data.data",Filter[0])
        return Filter && Filter[0] && Filter[0].data &&Filter[0].data.data && Filter[0].data.data.data!==null ? Filter[0].data.data.meta &&  String(Filter[0].data.data.meta.pagination.total).padStart(2,'0') : String(0).padStart(2, 0)
    }

    const headers = ["Source", "View Data", "Total number of Data Points"];


    return (
        <div className='Container-container'>
            <h1 className='Heading'>Environment</h1>
            <div className='PageDescription'>
                The environmental metrics are analyzed across three categories: {" "}
                <span className='BoldDescription'>Carbon Metrics</span>, {" "}
                <span className='BoldDescription'>Water Metrics</span>, {" "} & {""}
                <span className='BoldDescription'>Waste Metrics</span>. {" "}

            </div>

            <div className="HeadingTwo">
                <h3>
                    Carbon Metrics
                </h3>
            </div>
            <p className='Collection-Info'>Get to know the details of your company's greenhouse gas emissions by exploring your activity data</p>
            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Scope 1: Direct emissions'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {

                                    if (item.attributes.Type === "Direct emissions") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Scope 2: Indirect emissions'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {

                                    if (item.attributes.Type === "Indirect emissions") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Scope 3: Value Chain | Upstream'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {
                                    if (item.attributes.Type === "Value Chain | Upstream") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Scope 3: Value Chain | Downstream'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {
                                    if (item.attributes.Type === "Value Chain | Downstream") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <div className="HeadingTwo">
                <h3>
                    Water Metrics
                </h3>
            </div>
            <p className='Collection-Info'>Get to know the details of your company's water impact by exploring your water data.</p>
            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Water Quantity Data'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {
                                    if (item.attributes.Type === "Water Quantity Data") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>
            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Water Quality Data'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {
                                    if (item.attributes.Type === "Water Quality Data") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <div className="HeadingTwo">
                <h3>
                    Waste Metrics
                </h3>
            </div>
            <p className='Collection-Info'>Get to know the details of your company's waste impact by exploring your waste data.</p>
            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Waste Data'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnvironmentData && EnvironmentData.map((item) => {
                                    if (item.attributes.Type === "Waste Data") {
                                        const URLPath = item && item.attributes && item.attributes.Name && item.attributes.Name.replace(/\s+/g, '-');
                                        const PluralizedData = URLPath && doPluralize(URLPath);
                                        const Singularized = PluralizedData && PluralizedData.split("-")
                                            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div>
                                            </TableCell>
                                            <TableCell>{FilteredCount(Singularized)}</TableCell>
                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>


        </div>
    );
};

export default Environment;