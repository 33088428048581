import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextArea, Tag, TagSkeleton } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import './Onboarding.css';
import { UpdateAlldata, UpdateOneData, updateData } from '../APIController/APIControllers';
const EndOnboarding = () => {
    const navigate = useNavigate();
    const [FormData, setFormData] = useState({
        Name: '',
        Default_Approval: '',
        Country: '',
        Address: '',
        City: '',
        Postal: ''
    });

    const UserEmail = JSON.parse(sessionStorage.getItem('userData'))

    useEffect(() => {
        const OrganizationDetails = JSON.parse(sessionStorage.getItem("formData"));
        const Permissions = JSON.parse(sessionStorage.getItem("Approval_Permission"));
        console.log("OrganizationDetails", OrganizationDetails);
        console.log("AddessDetails", Permissions)
        setFormData({
            Name: OrganizationDetails && OrganizationDetails.organizationName,
            Default_Approval: Permissions,
            Country: OrganizationDetails && OrganizationDetails.country.label,
            Address: OrganizationDetails && OrganizationDetails.address,
            City: OrganizationDetails && OrganizationDetails.City,
            Postal: OrganizationDetails && OrganizationDetails.zipCode
        })
    }, [])

    console.log("FormData", FormData)

    const handleButtonclick = () => {
        const CollectionName = "tenant";
        const response = updateData(CollectionName, FormData).then((data) => {
            if (data.data) {
                navigate('/');
                sessionStorage.removeItem("formData")
                sessionStorage.removeItem("Step_02")
                sessionStorage.removeItem("Approval_Permission")
                sessionStorage.removeItem("country")
                sessionStorage.removeItem("Reporting_Template")
                window.location.reload();
            } else {
                alert(data && data.error.message)
            }

        })
            .catch((err) => {
                alert(err)
            })
    };
    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Begin your ESG Journey</div>
                <div className="thankyou-body">
                    Hey    <span className='bold'>
                        {UserEmail && UserEmail.user.email}
                    </span>
                    ,
                    <br></br>
                    <br></br>

                    Thank you for choosing AudytESG as your ESG Partner. Your choice of AudytESG reaffirms our collective dedication to driving positive change in the corporate world. Together, we can elevate your organization's ESG performance and contribute to a more sustainable future for us all. <br></br>
                    If you have any questions or require assistance at any point, our support team is readily available at
                    <span className='bold'> support-esg@audyt.io</span>. We value the trust you've placed in AudytESG and look forward to a successful partnership.
                    <br></br>
                    Thank you once again for choosing AudytESG as your preferred ESG software provider. We are excited about the positive impact we can create together.
                    <br></br>
                    <br></br>

                    Best Regards,
                    <div className='bold'>
                        Audyt Team
                    </div>


                </div>
                <Button className='onboarding-button' onClick={handleButtonclick} renderIcon={ArrowRight}>
                    Head over to your Audyt ESG Dashboard
                </Button>
            </div>
        </div>
    )
}

export default EndOnboarding