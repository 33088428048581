const userStateinitial = {
}

const userReducer = (state = userStateinitial, action) => {
    console.log(action)
    if (action.type === "LogoImage") {
        state = {
            ...state,
            img: action.payload
        }
        return state;
    }
    else if (action.type === "Filter_Data") {
        let filteredData = state.DynamicData.filter(item => {
            if (item.attributes.tags_job.data != null) {
                for (let key in item.attributes.tags_job.data.attributes) {
                    if (key === action.payload.keyData &&
                        item.attributes.tags_job.data.attributes[key] === action.payload.keyvalue) {
                        return item;
                    }
                }
                state = {
                    ...state,
                    DynamicData: filteredData
                }
                return state;
            }
        });
        return state;
    }
    else {
        return state;
    }
}

export default userReducer;