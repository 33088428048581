import React, { useEffect, useState } from 'react';
import {
    HeaderContainer,
    Header,
    SkipToContent,
    HeaderMenuButton,
    HeaderName,
    HeaderNavigation,
    HeaderMenuItem,
    HeaderMenu,
    HeaderGlobalBar,
    HeaderGlobalAction,
    SideNav,
    SideNavItems,
    SideNavMenu,
    SideNavMenuItem,
    SideNavLink,
    ToastNotification,
    Grid,
    Button,
    Column,
    Breadcrumb,
    Dropdown,
    BreadcrumbItem,
    ModalWrapper,
    Popover,
    PopoverContent,
    FormLabel
} from '@carbon/react';
import {
    PageNumber,
    Notification,
    Help,
    UserAvatar,
    ChartEvaluation,
    RequestQuote,
    UserMultiple,
    IbmWatsonKnowledgeCatalog,
    MachineLearningModel,
    Dashboard,
    ChartSunburst,
    Settings,
    BookmarkAdd,
    Search,
    Chat,
    Logout,
    Power
} from '@carbon/icons-react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames';
import { getAlldata, getOnedata } from '../../APIController/APIControllers';


function HeaderComponent(props) {
    const Navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const OrganizationInfo = "organisation-profile";
    const [OrganizationProfile, setOrganizationProfile] = useState(null);
    const [OrganizationProfiles, setOrganizationProfiles] = useState("");
    const [UserDatails, setUserDetails] = useState(null);
    const [LogOutWarning, setLogOutWarning] = useState(false);
    const URLPath = window.location.pathname.split("/").slice(1);
    const alignTwo = document?.dir === 'rtl' ? 'bottom-left' : 'bottom-right';
    function capitalizeFirstLetter(str) {
        return str.replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }


    useEffect(() => {
        getAlldata("tenant").then((data) => {
            setOrganizationProfiles(data);
        })
    }, [])

    const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));

    useEffect(() => {
        if (OrganizationProfiles && OrganizationProfiles.data === null || JWT_Testing === null) {
            Navigate('/sign-in');
        }
    }, [OrganizationProfiles, Navigate]);

    const NavItems = [
        {
            icon: Dashboard,
            Title: "Dashboard",
            path: "/dashboard"
        },
        {
            icon: BookmarkAdd,
            Title: "Reference Data",
            path: "/reference-data"
        },
        {
            icon: ChartSunburst,
            Title: "ESG Metrics",
            path: "/environment",
            SubContent: [
                {
                    Title: "Environment",
                    path: "/environment"
                },
                {
                    Title: "Social",
                    path: "/social"
                },
                {
                    Title: "Governance",
                    path: "/governance"
                }
            ]
        },
        {
            icon: PageNumber,
            Title: "Evidences",
            path: "/evidences"
        },
        {
            icon: ChartEvaluation,
            Title: "Insights",
            path: "/insights"
        },
        // {
        //     icon: RequestQuote,
        //     Title: "Reports",
        //     path: "/reports"
        // },
        // {
        //     icon: IbmWatsonKnowledgeCatalog,
        //     Title: "Logs",
        //     path: "logs"
        // },
        // {
        //     icon: MachineLearningModel,
        //     Title: "AudytLLM",
        //     path: "audytLLM"
        // },
        {
            icon: UserMultiple,
            Title: "User Management",
            path: "/users"
        },
        {
            icon: Settings,
            Title: "Settings",
            path: "/settings"
        },
    ]


    const useResponsiveOffset = true
    const classNameFirstColumn = cx({
        'cds--col-lg-13': true,
        'cds--offset-lg-3': useResponsiveOffset
    });


    useEffect(() => {
        getAlldata(OrganizationInfo)
            .then((data) => { setOrganizationProfile(data.data) })
            .catch((err) => console.log(err))

        JWT_Testing && getOnedata("users", JWT_Testing.user.id)
            .then((data) => setUserDetails(data))
            .catch((err) => console.log(err))
    }, [])

    const OpenHandler = () => {
        setOpen(!open)
    }

    const NavigateHandler = (index) => {
        Navigate(-1);
    }

    // LOGOUT FUNCTION
    const LogOutHandler=()=>{
        sessionStorage.clear();
        window.location.reload();
    }


    return (
        <div className="cds--layout Container">
            <Header aria-label="IBM Platform Name" className="cds--w100" >
                <SkipToContent />
                <HeaderMenuButton onClick={OpenHandler} aria-label='true' aria-labelledby='true' />
                <HeaderName href="#" prefix={OrganizationProfiles && OrganizationProfiles.data && OrganizationProfiles.data.attributes.Name}>
                    [ESG Reporter]
                </HeaderName>
                <HeaderGlobalBar>
                    <HeaderGlobalAction aria-label="Notifications">
                    </HeaderGlobalAction>
                    <HeaderGlobalAction aria-label="Search" tooltipAlignment="end">
                        <Search />
                    </HeaderGlobalAction>
                    <HeaderGlobalAction aria-label="Feedback" tooltipAlignment="end">
                        <Chat />
                    </HeaderGlobalAction>
                    <HeaderGlobalAction aria-label="Notifications" tooltipAlignment="end">
                        <Notification />
                    </HeaderGlobalAction>
                    <HeaderGlobalAction aria-label="Log-out">
                    <div className="popover-tabtip-story">
                        <Popover open={LogOutWarning} isTabTip align={alignTwo} onRequestClose={() => setLogOutWarning(false)}>
                            <button aria-label="Settings" type="button" onClick={() => {
                                setLogOutWarning(!LogOutWarning);
                                
                            }}>
                                <Logout />
                            </button>
                            <PopoverContent className="Popup-warning">
                                <div style={{width:370}} className='Popup--modal'>
                                    <div className='Popup--message'>
                                 <FormLabel>Hey, {JWT_Testing&&JWT_Testing.user.email}</FormLabel>
                               <p>Are you sure you want to log out from Audyt?</p>
                               </div>
                               <div className='Log-out--button' >
                                <Button renderIcon={Power} iconDescription="Add" size='sm' onClick={LogOutHandler}>Log out</Button>
                               </div>
                                </div>
                            </PopoverContent>
                        </Popover>
                        </div>
                    </HeaderGlobalAction>

                </HeaderGlobalBar>
                <SideNav aria-label="Side navigation" expanded={open} isRail >
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
                        <SideNavItems >
                            {NavItems && NavItems.map((item, index) => {
                                if (item && item.SubContent) {
                                    return <SideNavMenu key={index} renderIcon={ChartSunburst} title="ESG Metrics">
                                        {item && item.SubContent && item.SubContent.map((navitem, index) => {
                                            return <SideNavMenuItem onClick={() => Navigate(`${navitem.path}`)}>
                                                {navitem.Title}
                                            </SideNavMenuItem>
                                        })}
                                    </SideNavMenu>
                                } else {
                                    return <SideNavLink renderIcon={item.icon} onClick={() => Navigate(`${item.path}`)}>
                                        {item.Title}
                                    </SideNavLink>
                                }
                            })}
                        </SideNavItems>

                        <div>
                            <SideNavItems >
                                <SideNavLink onClick={() => Navigate("/my-profile")}>
                                    <UserAvatar /> <span>{UserDatails && UserDatails.email}</span>
                                </SideNavLink>
                            </SideNavItems>
                        </div>

                    </div>
                </SideNav>
            </Header>
            <main className="cds--content" style={URLPath[0] === 'dashboard' || URLPath[0] === 'insights' || URLPath[0] === "" ? { height: "100%" } : {}}>
                <div className="selecterMove">
                    <div className="Selector">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a onClick={() => { Navigate("/") }}>{OrganizationProfiles && OrganizationProfiles.data && OrganizationProfiles.data.attributes.Name}</a>
                            </BreadcrumbItem>
                            {URLPath && URLPath.map((URL, index) => {

                                if (URL !== "") {
                                    return <BreadcrumbItem onClick={() => NavigateHandler(index)}>{capitalizeFirstLetter(URL)}</BreadcrumbItem>
                                }
                            })}
                        </Breadcrumb>
                        <Outlet />
                    </div>
                </div>
            </main>

        </div>
    );
}
export default HeaderComponent;
