import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import "./index.scss";
import Signup from './Administration/Signup/Signup';
import Login from './Administration/Login/Login';
import { getAlldata } from './APIController/APIControllers';
import { useEffect, useState } from 'react';
import UserOnboarding from './Administration/UserOnboarding/UserOnboarding';
import InvitedUserOnboarding from './Administration/InvitedUserUpdateProfile/InvitedUserOnboarding';
import InvitedUserUpdateProfile from './Administration/InvitedUserUpdateProfile/InvitedUserUpdateProfile';
import SetupProfile from './Administration/SetupProfile/SetupProfile';
import HeaderSection from './MinorComponents/Header/Header';
import Dashboard from './MainComponents/Dashboard/Dashboard';
import Environment from './MainComponents/Metrics/Environment/Environment';
import Social from './MainComponents/Metrics/Social/Social';
import Governance from './MainComponents/Metrics/Governance/Governance';
import Evidence from './MainComponents/Evidence/Evidence';
import Insights from './MainComponents/Insights/Insights';
import Reports from './MainComponents/Reports/Reports';
import Logs from './MainComponents/Logs/Logs';
import AudytLLM from './MainComponents/AudytLLM/AudytLLM';
import UserManagement from './MainComponents/UserManagement/UserManagement';
import Settings from './MainComponents/Settings/Settings';
import Table from './DynamicComponent/TableComponent/Table';
import CompanyProfile from './DynamicComponent/CompanyProfile/CompanyProfile';
import ReferenceData from './MainComponents/ReferenceData/ReferenceData';
import CreateNew from './DynamicComponent/Create/CreateNew';
import ReadData from './DynamicComponent/ReadData/ReadData';
import ViewTable from './MainComponents/Metrics/ViewTable';
import CreateNewMetric from './MainComponents/Metrics/CreateNewMetric';

import Onboarding from './Onboarding/Onboarding';
import CreateReportingYear from './Onboarding/CreateReportingYear';
import OnboardingApproval from './Onboarding/OnboardingApproval';
import InviteOnboarding from './Onboarding/InviteOnboarding';
import RoleOnboarding from './Onboarding/RoleOnboarding';
import EndOnboarding from './Onboarding/EndOnboarding';
import PasswordlessLogin from './Administration/SignIn/PasswordlessLogin';
// import PasswordlessSignUp from './Administration/Signup/PasswordlessSignUp';
import OTPLogin from './Administration/SignIn/OTPLogin';
import OTPSignup from './Administration/Signup/OTPSignup';

//supertoken imports
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import { GoogleAuth } from './Administration/SupertokenController';
import GoogleRedirect from './Administration/SignIn/GoogleRedirect';
import SignIn from './Administration/SignIn/SignIn';
import Testpage from './Calc/Testpage';
import Profile from './MinorComponents/User_Profile/Profile';
import ReadSingleTypeMatrics from './MainComponents/Metrics/Social/ReadSingleTypeMatrics';

SuperTokens.init({
  appInfo: {
    appName: "audyt",
    apiDomain:  `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/`,
    websiteDomain: `${process.env.REACT_APP_IP_ADDRESS}/`,
    apiBasePath: "/auth",
    websiteBasePath: "/"
  },
  recipeList: [
    Passwordless.init({
      contactMethod: "EMAIL"
    }),
    Session.init()
  ]
});
function App() {
  const [OrganizationProfile, setOrganizationProfile] = useState("");

 
  useEffect(() => {
    getAlldata("tenant").then((data) => {
      setOrganizationProfile(data.data);
     
    })
  }, [])


  return (
    <SuperTokensWrapper>

      <div style={{ width: "100%", height: "100%", }}>
        <BrowserRouter>

          <Routes>
            
            <Route exact path="/sign-in" element={<SignIn/>}/>
            <Route exact path='/create-organisation' element={<Onboarding />} />
            <Route exact path='/create-reporting-year' element={<CreateReportingYear />} />
            <Route exact path="/select-approval" element={<OnboardingApproval />} />
            <Route exact path="/invite-your-team" element={<InviteOnboarding />} />
            {/* <Route exact path="/add-roles" element={<RoleOnboarding />} /> */}
            <Route exact path="/thank-you" element={<EndOnboarding />} />
            <Route exact path='/calc' element = {<Testpage/>}/>
            <Route exact path='/' element={<HeaderSection />}>
              <Route exact path='/' element={<Dashboard />} />
              <Route exact path='/dashboard' element={<Dashboard />} />


              <Route exact path='/reference-data/:_pathId' element={<Table />} />
              <Route exact path='/reference-data/:_pathId/:_Id' element={<ReadData />} />
              <Route exact path='/reference-data/:_pathId/create' element={<CreateNew />} />
              <Route exact path='/reference-data' element={<ReferenceData />} />


              <Route exact path='/environment' element={<Environment />} />
              <Route exact path='/environment/:_pathId' element={<ViewTable />} />
              <Route exact path='/environment/:_pathId/:_Id' element={<ReadData />} />
              <Route exact path='/environment/:_pathId/create' element={<CreateNewMetric />} />

              <Route exact path='/social' element={<Social />} />
              <Route exact path='/social/:_pathId' element={<ReadSingleTypeMatrics />} />
              <Route exact path='/social/:_pathId/create' element={<CreateNewMetric />} />

              <Route exact path='/governance' element={<Governance />} />
              <Route exact path='/governance/:_pathId' element={<ReadSingleTypeMatrics />} />
              <Route exact path='/governance/:_pathId/create' element={<CreateNewMetric />} />

              <Route exact path='/evidences' element={<Evidence />} />
              <Route exact path='/insights' element={<Insights />} />
              <Route exact path='/Reports' element={<Reports />} />
              <Route exact path='/logs' element={<Logs />} />
              <Route exact path='/audytLLM' element={<AudytLLM />} />
              <Route exact path='/users' element={<UserManagement />} />
              <Route exact path='/my-profile' element={<Profile/>} />

              <Route exact path='/settings' element={<Settings />} />

              <Route exact path='/settings/:_pathId' element={<Table />} />
              <Route exact path='/settings/company-profile' element={<CompanyProfile />} />
            </Route>
            {/* <Route exact path="/login" element={<Login OrganizationProfile={OrganizationProfile} />} /> */}
            <Route exact path="/passwordless-sign-in" element={<PasswordlessLogin />} />
            {/* <Route exact path="/passwordless-signup" element={<PasswordlessSignUp />} /> */}
            <Route exact path='/verify-login' element={<OTPLogin />} />
            <Route exact path="/authenticate/google" element={<GoogleRedirect/>} />
            <Route exact path='/verify-signup' element={<OTPSignup />} />
            <Route exact path="/signup" element={<Signup OrganizationProfile={OrganizationProfile} />} />
            <Route exact path='/memberOnboarding' element={<UserOnboarding OrganizationProfile={OrganizationProfile} />} />
            <Route exact path='/invited/:id' element={<InvitedUserOnboarding OrganizationProfile={OrganizationProfile} />} />
            <Route exact path='/invitedOnboarding/:id' element={<InvitedUserUpdateProfile OrganizationProfile={OrganizationProfile} />} />
            <Route exact path='/setupProfile' element={<SetupProfile OrganizationProfile={OrganizationProfile} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </SuperTokensWrapper>

  );
}

export default App;
