import React, { useState } from 'react';
import Loading from '../../MinorComponents/Loading/Loader';

const Insights = () => {
    const [Loader, setLoader] = useState(true);

    setTimeout(() => {
        setLoader(false)
    }, 5000)

    const URL = "https://us-east-metabase.nanoheal.app/public/dashboard/d4889cab-87f1-47a3-8df1-6fe731b7a273";


    return (
        <div className='Container-container' style={{ height: "-webkit-fill-available" }}>
            <h1 className='Dashboard-Heading'>Insights</h1>
            <div className='PageDescription'>
                Connecting to reference data helps provide relevant, reliable calculations accross all ESG metrics.
            </div>
            <div className='cds--content IframeCustom'>
                {Loader &&<Loading /> }
                    <iframe
                        src={URL}
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        allowTransparency
                    />
            </div>
        </div>
    );
};

export default Insights;