import React from 'react';
import Lottie from 'react-lottie';
import animationData from './Loader.json'
const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div style={{position:"absolute",inset:0, background:"#F7F9F9",height:"120%",display:"flex",justifyContent:"center", alignItems:"center"}}>
   <Lottie 
	    options={defaultOptions}
        height={100}
        width={100}
      />
    </div>
  )
}

export default Loading;