import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLoginJWT, LoginUserGrabJwt } from '../../APIController/AdministrationController';
import { Button, Header, HeaderName, TextInput } from '@carbon/react';
import { ArrowRight, Cloud, Email } from '@carbon/icons-react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Information } from '@carbon/icons-react';
import { GoogleAuth, getGoogleInfo } from '../SupertokenController';


const Login = (props) => {
    const navigate = useNavigate();

    const handleNavigatePasswordless = () => {
        navigate('/passwordless-sign-in')

    }
    const handleGoogleAuth = async () => {
        const data = await GoogleAuth();
        console.log(data.urlWithQueryParams);
        window.location.href = data.urlWithQueryParams;
    }
    return (
        <div className="onboarding-container">
            <img src="/logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Login to Audyt ESG</div>
                <div className="Onboarding-Body">
                    Welcome to Audyt, our mission is to transform companies
                    with precision analytics and AI for a sustainable future.
                </div>
                <Button className='primary-login-button' onClick={handleNavigatePasswordless} renderIcon={Email}>
                    Continue with email
                </Button>
                <Button className='secondary-login-button' onClick={handleGoogleAuth} renderIcon={Cloud}>
                    Continue with Google

                </Button>
                <div className='helper-container'>
                    <Information className='icon' />
                    We recommend signing in with your work email
                </div>
                {/* <div className='Signup-container'>
                    Don't have an account yet? &nbsp;

                    <div className='bold-link' onClick={handleOnNavigate}> {" "}Sign up {">"}</div>
                </div> */}
            </div>
        </div>
    )
}

export default Login;