// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .app {
      width: 100%;
      min-height: 100vh;
      background: #fbfbff;
    }

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"IAAI;MACE,WAAW;MACX,iBAAiB;MACjB,mBAAmB;IACrB","sourcesContent":["    .app {\n      width: 100%;\n      min-height: 100vh;\n      background: #fbfbff;\n    }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
