import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetFilteredData, StringLimit, UserAdminTesting, doPluralize, getAlldata } from '../../APIController/APIControllers';
import { Button, DataTable, Dropdown, SkeletonText, TextInput } from '@carbon/react';
import pluralize from 'pluralize';
import { Add } from '@carbon/icons-react';

const {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow
} = DataTable;

const ViewTable = () => {
    const params = useParams();
    const Navigate = useNavigate();
    const [DynamicData, setDynamicData] = useState(null);
    const [collectionInfos, setCollectionInfos] = useState(null);
    const singularTerm = pluralize.singular(params._pathId);
    const Capitalize=singularTerm&&singularTerm.charAt(0).toUpperCase()+singularTerm.slice(1);
    const [UserData, setUserData] = useState(null);
    const [referenceData, setReferenceData] = useState(null);
    const [TenantData, setTenantData] = useState(null);
    const [MatricsInfo,setMatricsInfo] = useState([]);
    const collectionName = params._pathId?.replaceAll("-", ' ').split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"))

    const Location = useLocation();
    const URLPath = Location && Location.pathname && Location.pathname.split("/");
    const MatricsName = URLPath && URLPath[1];
  

    const Matrics = `${MatricsName}-metrics`

    // CHECKING THE SERVER STATUS
    const [Forbidden, setForbidden] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [NullData, setNullData] = useState(false);
    const [PageNotFound, setPageNotFound] = useState(false);
    const [Errors, setErrors] = useState(false);

    URLPath && console.log("URLPath", URLPath[2])

    useEffect(() => {
        getAlldata(params._pathId)
            .then((data) => {
                if (data && data.error && data.error.status === 403) {
                    setIsOnline(false);
                    setNullData(false);
                    setForbidden(true);
                    setErrors(false);
                    setPageNotFound(false);
                } else if (data.data && data.data.length > 0) {
                    setIsOnline(false);
                    setForbidden(false);
                    setErrors(false);
                    setPageNotFound(false);
                    setDynamicData(data.data);
                } else if (data.data && data.data.length === 0) {
                    setNullData(true);
                    setIsOnline(false);
                    setForbidden(false);
                    setErrors(false);
                    setPageNotFound(false);
                } else if (data && data.error && data.error.status === 404) {
                    setErrors(false);
                    setForbidden(false);
                    setIsOnline(false);
                    setNullData(false);
                    setPageNotFound(true);
                }
                else if (data && data.error && data.error.status !== 403 && data.error.status !== 404) {
                    setErrors(true);
                    setForbidden(false);
                    setIsOnline(false);
                    setNullData(false)
                    setPageNotFound(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })


        getAlldata('tenant').then((data) => {
            setTenantData(data.data)
        })
            .catch((err) => {
                console.log(err)
            })

        UserAdminTesting(UserDetails.user.id)
            .then((data) => { setUserData(data) })
            .catch((Err) => console.log(Err))

            GetFilteredData(Matrics,Capitalize)
            .then((data)=>setMatricsInfo(data.data))
            .catch((err)=>console.log(err))
    }, [])

    const NavigateHandler = () => {
        Navigate("create")
    }


    const newData = DynamicData && DynamicData.map(item => {
        const { updatedAt, publishedAt, ...rest } = item.attributes;
        return { ...item, attributes: rest };
    });

    const SortingData = newData && newData.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))

    const ObjectData = SortingData && SortingData[0] && Object.keys(SortingData[0].attributes).slice(0, 5);

    const DescriptionData = collectionInfos && collectionInfos.filter((data) => {
        const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
        const PluralizedData = URLPath && doPluralize(URLPath);
        const Singularized = PluralizedData && PluralizedData.split("-")
            .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        return Singularized === params._pathId
    })

    const approval_list = ["No approval flow", "One Step Approval", "Two Step Approval"];

    const ReadDataHandler = (item) => {
        Navigate(`${item.id}`)
    }

    console.log("MatricsInfo",MatricsInfo)

    return (
        <div className='Container-container'>
            {Forbidden ? <h1>403 Forbidden</h1> : <>
                {PageNotFound ? <h1>404 Page not found.</h1> : <>
                    {Errors ? <h1>Loading</h1> : <>
                        <h1>{collectionName}</h1>
                        <p className='Collection-Info'>{MatricsInfo && MatricsInfo[0] && MatricsInfo[0].attributes && MatricsInfo[0].attributes.Description}</p>
                        {/* {UserData && <>
                            {TenantData && TenantData.attributes &&
                                <>
                                    <Dropdown
                                        disabled
                                        id={`select-1`}
                                        titleText="Approval Process"
                                        items={approval_list}
                                        initialSelectedItem={TenantData.attributes.Default_Approval}
                                    />
                                    <br />
                                </>}
                        </>} */}
                        <div className='AddNewBtn'>
                            <Button renderIcon={Add} iconDescription="Add" onClick={NavigateHandler}>New</Button>
                        </div >
                        {NullData ? <h1>No data</h1> :
                            <Table aria-label="sample table">
                                <TableHead>
                                    <TableRow>
                                        {ObjectData && ObjectData.map((data, index) => {
                                            // Replace underscores with spaces in the header
                                            const columnHeader = data.replace(/_/g, ' ');
                                            return <TableHeader key={index}>
                                                {columnHeader}
                                            </TableHeader>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {SortingData && SortingData.map((item, index) => {
                                        return <TableRow key={index} onClick={() => ReadDataHandler(item)}>
                                            {ObjectData && ObjectData.map((data) => {
                                                const NewData = item && item.attributes[data]
                                                var MytableData;
                                                console.log('%cTable.jsx line:98 NewData', 'color: #007acc;', NewData);
                                                if (NewData === false || NewData === true) {
                                                    MytableData = item && JSON.stringify(item.attributes[data]);
                                                } else if (data === "Description") {
                                                    MytableData = item && item.attributes[data] && StringLimit(item.attributes[data], 120);
                                                } else {
                                                    MytableData = item && item.attributes[data]
                                                }
                                                return <TableCell>
                                                    {MytableData && MytableData.data !== null ? MytableData : ""}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        }
                        {isOnline &&
                            <Table aria-label="sample table">
                                <TableHead>

                                    <TableRow>
                                        {[1, 2, 3, 4, 5].map((data, index) => (
                                            <TableHeader>
                                                <SkeletonText />
                                            </TableHeader>
                                        ))}
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {[1, 2, 3].map((item, index) => (
                                        <TableRow>
                                            {[1, 2, 3, 4, 5].map((data, index) => (
                                                <TableCell>
                                                    <SkeletonText />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </>}
                </>}
            </>}
        </div>
    )
}

export default ViewTable