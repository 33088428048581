import React, { useEffect, useState } from 'react';

import { Accordion, AccordionItem, Table, TableRow, TableHead, TableHeader, TableBody, TableCell } from '@carbon/react';
import { StringLimit, doPluralize, getAlldata } from '../../../APIController/APIControllers';
import { useNavigate } from 'react-router-dom';
import pluralize from "pluralize"



const Social = () => {
    const navigate = useNavigate();

    const [SocialMatrics, setSocilMatrics] = useState([]);



    const HandleToNavigate = (data) => {
        // const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-')
        const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/[\s_]+/g, '-').replace(/-+/g, '-').trim();
        const PluralizedData = pluralize.singular(URLPath);
        console.log("PluralizedData", PluralizedData)
        const Singularized = PluralizedData.split("-")
            .map(word => word && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
        const Navigator = Singularized.toLowerCase();
        navigate(`${Navigator}`)
    }





    const [skills, setSkills] = useState(null);
    useEffect(() => {
        getAlldata("social-metrics")
            .then((data) => {
                setSocilMatrics(data.data)
            })
            .catch((err) => {
                console.log(err);
            })

    }, [])

    console.log("Social matrics", SocialMatrics)







    const headers = ["Source", "Description", "View Data"];


    return (
        <div className='Container-container'>
            <h1 className='Heading'>Social</h1>
            <div className='PageDescription'>
                Here are the social metrics which are analyzed various categories: {" "}
                <span className='BoldDescription'>People Metrics</span> {" "} & {""}
                <span className='BoldDescription'>Prosperity Metrics.</span>
            </div>
            <div className="HeadingTwo">
                <h3>

                    People & Prosperity Metrics
                </h3>
                <p className='Collection-Info'>Get to know the details of your organization's people metrics by exploring your activity data</p>
            </div>

            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Employee Diversity'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SocialMatrics && SocialMatrics.map((item) => {
                                    if (item && item.attributes.Type === "Employee Diversity") {
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 80)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div></TableCell>

                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>


            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Employee Opportunity'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SocialMatrics && SocialMatrics.map((item) => {
                                    if (item && item.attributes.Type === 'Employee Opportunity') {
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 80)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div></TableCell>

                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>


            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Fair Wages'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SocialMatrics && SocialMatrics.map((item) => {
                                    if (item && item.attributes.Type === 'Fair Wages') {
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 80)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div></TableCell>

                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>

            <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Supplier Standards'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SocialMatrics && SocialMatrics.map((item) => {
                                    if (item && item.attributes.Type === 'Supplier Standards') {
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 80)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div></TableCell>

                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>    <Accordion size='lg' isFlush={true}>
                <AccordionItem title='Working Conditions - Healthcare'>
                    <div className='TableAccordian'>
                        <Table aria-label="sample table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map(header => <TableHeader id={header.key} key={header}>
                                        {header}
                                    </TableHeader>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SocialMatrics && SocialMatrics.map((item) => {
                                    if (item && item.attributes.Type === 'Working Conditions - Healthcare') {
                                        return <TableRow key={item.id}>
                                            <TableCell>{item.attributes.Name}</TableCell>
                                            <TableCell>{item && item.attributes && item.attributes.Description && StringLimit(item.attributes.Description, 80)}</TableCell>
                                            <TableCell>
                                                <div className='link-color' onClick={() => HandleToNavigate(item)}>
                                                    View Source
                                                </div></TableCell>

                                        </TableRow>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </AccordionItem>
            </Accordion>
        </div>

    );
};

export default Social;