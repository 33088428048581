import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { StringLimit, UserAdminTesting, doPluralize, getAlldata } from '../../APIController/APIControllers';
import { Button, DataTable, Dropdown, SkeletonText, TextInput } from '@carbon/react';
import pluralize from 'pluralize';
import { Add } from '@carbon/icons-react';
const {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow
} = DataTable;

const DynamicTable = () => {
  const params = useParams();
  const Navigate = useNavigate();
  const [DynamicData, setDynamicData] = useState(null);
  const [collectionInfos, setCollectionInfos] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [TenantData, setTenantData] = useState(null);
  const singularTerm = pluralize.singular(params._pathId);
  const [UserData, setUserData] = useState(null);
  const collectionName = params._pathId?.replaceAll("-", ' ').split(" ")
  .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"))
  
  
  // CHECKING THE SERVER STATUS
  const [Forbidden, setForbidden] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [NullData, setNullData] = useState(false);
  const [PageNotFound, setPageNotFound] = useState(false);
  const [Errors, setErrors] = useState(false);

  useEffect(() => {
    const checkConnection = () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
    };

    checkConnection();

    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);

    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  useEffect(() => {
    getAlldata('references').then((data) => {
      setCollectionInfos(data.data)
    })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const DescriptionData = collectionInfos && collectionInfos.filter((data) => {
    const URLPath = data && data.attributes && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-');
    const PluralizedData = URLPath && doPluralize(URLPath);
    const Singularized = PluralizedData && PluralizedData.split("-")
      .map(word => word && word.charAt(0) && word.charAt(0).toLowerCase() + word.slice(1)).join("-");
    return Singularized === params._pathId
  })
  
  useEffect(() => {
    setIsOnline(true);
    getAlldata(params._pathId)
      .then((data) => {

        if (data && data.error && data.error.status === 403) {
          setIsOnline(false);
          setNullData(false);
          setForbidden(true);
          setErrors(false);
          setPageNotFound(false);
        } else if (data.data && data.data.length > 0) {
          setIsOnline(false);
          setForbidden(false);
          setErrors(false);
          setPageNotFound(false);
          setDynamicData(data.data);
        } else if (data.data && data.data.length === 0) {
          setNullData(true);
          setIsOnline(false);
          setForbidden(false);
          setErrors(false);
          setPageNotFound(false);
        } else if (data && data.error && data.error.status === 404) {
          setErrors(false);
          setForbidden(false);
          setIsOnline(false);
          setNullData(false);
          setPageNotFound(true);
        }
        else if (data && data.error && data.error.status !== 403 && data.error.status !== 404) {
          setErrors(true);
          setForbidden(false);
          setIsOnline(false);
          setNullData(false)
          setPageNotFound(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })

    getAlldata('tenant').then((data) => {
      setTenantData(data.data)
    })
      .catch((err) => {
        console.log(err)
      })

    UserAdminTesting(UserDetails.user.id)
      .then((data) => { setUserData(data) })
      .catch((Err) => console.log(Err))
  }, [])

  const NavigateHandler = () => {
    Navigate("create")
  }

  const newData = DynamicData && DynamicData.map(item => {
    const { updatedAt, publishedAt, ...rest } = item.attributes;
    return { ...item, attributes: rest };
  });



  const SortingData = newData && newData.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
  const formattedData = SortingData && SortingData.map(item => ({
    ...item,
    attributes: {
      ...item.attributes,
      createdAt: new Date(item.attributes.createdAt).toLocaleDateString('en-GB'),
    },
  }));
  const ObjectData = formattedData && formattedData[0] && Object.keys(formattedData[0].attributes).slice(0, 5);

  const HeaderData=ObjectData&& ObjectData.filter((name) => !name.startsWith("dumper"));
  const approval_list = ["No approval flow", "One Step Approval", "Two Step Approval"];


  const ReadDataHandler = (item) => {
    Navigate(`${item.id}`)
  }

  // console.log("MytableData**************",MytableData)

  return (
    <div className='Container-container'>
      {Forbidden ? <h1>403 Forbidden</h1> : <>
        {PageNotFound ? <h1>404 Page not found.</h1> : <>
          {Errors ? <h1>Loading</h1> : <>
            <h1>{collectionName}</h1>
            <p className='Collection-Info'>{DescriptionData && DescriptionData[0] && DescriptionData[0].attributes.Description}</p>
            {/* {UserData && <>
              {TenantData && TenantData.attributes &&
                <>
                  <Dropdown
                    // disabled
                    style={{ width: 300 }}
                    id={`select-1`}
                    items={approval_list}
                    titleText="Approval Process"
                    initialSelectedItem={TenantData.attributes.Default_Approval}
                  />
                  <br />
                </>}
            </>} */}
            <div className='AddNewBtn'>
              <Button renderIcon={Add} iconDescription="Add" onClick={NavigateHandler}>New</Button>
            </div >
            {NullData ? <p>No data points are entered for this source. Click on "New" to create a datapoint</p> :
              <Table aria-label="sample table">
                <TableHead>
                  <TableRow>
                    {HeaderData && HeaderData.map((data, index) => {
                      // Replace underscores with spaces in the header
                      const columnHeader = data && data.replace(/_/g, ' ');
                      return <TableHeader key={index}>
                        {columnHeader}
                      </TableHeader>
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formattedData && formattedData.map((item, index) => {
                    return <TableRow key={index} onClick={() => ReadDataHandler(item)}>
                      {HeaderData && HeaderData.map((data) => {
                        const NewData = item && item.attributes[data];
                        var MytableData;
                        if (NewData === false || NewData === true) {
                          MytableData = item && JSON.stringify(item.attributes[data]);
                          
                        } else if (data === "Description") {
                          MytableData = item && item.attributes[data] && StringLimit(item.attributes[data], 120);
                         
                        } else {
                          MytableData = item && item.attributes[data]
                        }

                        return <TableCell>
                          {MytableData && MytableData.data !== null ? MytableData : ""}
                        </TableCell>
                      })}
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            }
            {isOnline &&
              <Table aria-label="sample table">
                <TableHead>
                  <TableRow>
                    {[1, 2, 3, 4, 5].map((data, index) => (
                      <TableHeader>
                        <SkeletonText />
                      </TableHeader>
                    ))}
                  </TableRow>

                </TableHead>
                <TableBody>
                  {[1, 2, 3].map((item, index) => (
                    <TableRow>
                      {[1, 2, 3, 4, 5].map((data, index) => (
                        <TableCell>
                          <SkeletonText />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </>}
        </>}
      </>}
    </div>
  )
}


export default DynamicTable;