// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/Images/Background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Onboarding.css */

.onboarding-container {

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-position: center; 

    /* Add any other styling you need for the onboarding container */
  }

  .onboarding {

  }`, "",{"version":3,"sources":["webpack://./src/Onboarding/Onboarding.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;;IAEI,yDAAmD;IACnD,wBAAwB;IACxB,2BAA2B;;IAE3B,gEAAgE;EAClE;;EAEA;;EAEA","sourcesContent":["/* Onboarding.css */\n\n.onboarding-container {\n\n    background-image: url('/src/Images/Background.svg');\n    background-size: contain;\n    background-position: center; \n\n    /* Add any other styling you need for the onboarding container */\n  }\n\n  .onboarding {\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
