import React, { useEffect, useRef, useState } from 'react';
import { getAlldata } from '../../APIController/APIControllers';
import {
    Table,
    TableRow,
    TableHead,
    TableHeader,
    SideNavMenuItem,
    TableBody,
    TableCell,
    SideNav,
    SideNavItems,
    SideNavLink,
    SideNavMenu,
    HeaderMenu,
    HeaderMenuItem,
    HeaderSideNavItems,
    HeaderGlobalAction,
    HeaderPanel,
    Switcher,
    SwitcherItem,
    SwitcherDivider,
    Header
} from '@carbon/react';
import { Fade, Close } from '@carbon/icons-react';

const UserManagement = () => {
    const [userData, setUserData] = useState([]);
    const [Sidepanel, setSidePanel] = useState(true);
    const [SelectedUser, setSelectedUser] = useState(null);
    const [isSideNavExpanded, setisSideNavExpanded] = useState(false);
    const params_pathId = "users";
    const collectionName = params_pathId?.replace("-", " ").split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

    useEffect(() => {
        getAlldata(collectionName)
            .then((data) => {
                setUserData(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const divRef=useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (divRef.current && !divRef.current.contains(event.target)) {
            setisSideNavExpanded(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const headers = ["Name", "Email", "Role"];

    const selectHandler = (useData) => {
        setSelectedUser(useData);
        setisSideNavExpanded(!isSideNavExpanded);
    }

    console.log("isSideNavExpanded",isSideNavExpanded)

    return (
        <>
        <div className='Container-container' ref={divRef}>
            <h1 ref={divRef} className='Heading'>User Management</h1>
            <div ref={divRef} className='PageDescription'>
                Manage all your users and their roles in your ESG reporting journey.
            </div>
            <div className='DataTable' ref={divRef}>
                <Table size="md" useZebraStyles={false} aria-label="sample table" experimentalAutoAlign={true}>
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableHeader id={header} key={header}>
                                    {header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData.map((user) => (
                            <TableRow key={user.id} onClick={() => selectHandler(user)} tooltipAlignment="end" id="switcher-button">
                                <TableCell>
                                    {user.FirstName && user.LastName ? `${user.FirstName} ${user.LastName}` : '-'}
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
              
            </div>
        </div>
          <div >
          <Header >
              <HeaderPanel expanded={isSideNavExpanded} style={isSideNavExpanded ? { inlineSize: "36rem" } : {}}>
                  <div className="Overlay-Component" >
                      <div style={{ textAlign: "end" }}>
                          <Close size='24' onClick={() => setisSideNavExpanded(false)} />
                      </div>
                      <div >
                          <p>Username: {SelectedUser && SelectedUser.username}</p>
                          <p>Email: {SelectedUser && SelectedUser.email}</p>
                          <p>Role: {SelectedUser && SelectedUser.role.name}</p>
                          <p>Created at: {SelectedUser && SelectedUser.createdAt}</p>
                      </div>
                  </div>
              </HeaderPanel>
          </Header>
      </div>
      </>
    );
};

export default UserManagement;
