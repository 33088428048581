import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGoogleInfo } from '../SupertokenController';
import { NavigateSign } from '../../APIController/APIControllers';

const GoogleRedirect = () => {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(null);
    const [route, setRoute] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Extract code from URL
        const urlSearchParams = new URLSearchParams(location.search);
        const code = urlSearchParams.get('code');

        getGoogleInfo(code)
            .then((data) => {
                setUserInfo(data);
            })
            .catch((error) => {
                console.error('Error fetching Google info:', error);
            });
    }, [location.search]);

    useEffect(() => {
        // Call NavigateSign function when userInfo is updated
        if (userInfo) {
            const email = userInfo.email;
            NavigateSign(email)
                .then((navigate_route) => {
                    console.log('Navigate Route:', navigate_route);
                    setRoute(navigate_route);

                        navigate(navigate_route, { replace: true });

                    // You can then use navigateRoute to navigate to the desired route
                    // navigate(navigateRoute);
                })
                .catch((error) => {
                    console.error('Error in NavigateSign:', error);
                });
        }
    }, [userInfo]);
    // window.location.href = route;
    return (
        <div>
            <p>Handling Authentication...</p>
        </div>
    );
}

export default GoogleRedirect;
