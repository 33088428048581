import { React, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import CloseIcon from '@mui/icons-material/Close';
import AvatarEditor from 'react-avatar-editorz';
import { getOnedata, UpdateOneUserData } from '../../APIController/APIControllers';

const InvitedUserUpdateProfile = (props) => {
    const param = useParams()
    const [Mydata, setMydata] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [ImagePreview, setImagePreview] = useState();
    const [Open, setOpen] = useState(false);
    const [warningOpen, setWarningOpen] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [bio, setBio] = useState('');
    const [location, setLocation] = useState('');
    const [linkedIn, setLinkedIn] = useState();
    const [github, setGithub] = useState();
    const [designation, setDesignation] = useState('');
    const [BioError, setBioError] = useState("");
    const [DesignationError, setDesignationError] = useState("");
    const [LocationError, setLocationError] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, serLastNameError] = useState("");
    const CRCLName = props.OrganizationProfile && props.OrganizationProfile.attributes.Name;
    const [password, setPassword] = useState(null);
    const [LinkedInError, setLinkedInError] = useState("");
    const [GithubError, setGithubError] = useState("");
    const [passwordError, setPasswordError] = useState(null)
    const [showPassword, setShowPassword] = useState(false);
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const UrlPatternRegex =
        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    const emailRef = useRef(null);

    const encrypted = param.id
    const id = param.id;


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const Navigate = useNavigate();
    const editorRef = useRef(null);

    const FileUpdateHandler = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setOpen(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log(id)
        getOnedata("users", id).then((data) => {
            setMydata(data)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        //   Default values
        const EMAIL = Mydata && Mydata.email
        const FIRSTNAME = Mydata && Mydata.FirstName
        const LASTNAME = Mydata && Mydata.LastName
        const BIO = Mydata && Mydata.Bio
        const LINKEDIN = Mydata && Mydata.LinkedIn;
        const GITHUB = Mydata && Mydata.Github;
        const LOCATION = Mydata && Mydata.Location;
        const DESIGNATION = Mydata && Mydata.Designation;
        setFirstName(FIRSTNAME)
        setLastName(LASTNAME)
        setBio(BIO)
        setLinkedIn(LINKEDIN)
        setGithub(GITHUB)
        setLocation(LOCATION)
        setDesignation(DESIGNATION)
        setEmail(EMAIL)
    }, [Mydata])


    console.log("Mydata", Mydata)
    console.log("firstName", firstName)
    console.log("lastName", lastName)


    const handleCropImage = async () => {
        const editor = editorRef.current;
        const croppedData = editor.getImageScaledToCanvas().toDataURL();
        setImagePreview(croppedData)

        // Functionality to upload CRCL Image
        try {
            const formData = new FormData();
            const file = await fetch(croppedData);
            const blob = await file.blob();
            formData.append('files', blob, 'croppedImage.png');
            let jwt;
            if (JSON.parse(localStorage.getItem("userData"))) {
                jwt = JSON.parse(localStorage.getItem("userData")).jwt;
            } else {
                jwt = "";
            }
            const response = await axios.post(
                `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": 'multipart/form-data',
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            );
            const fileUrl = response.data[0];

            const fileData = {
                Profilepicture: fileUrl,
            };
            // await updateData(`users`, fileData);
            await axios.put(`${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/${JSON.parse(localStorage.getItem("userData")).user.id}/?populate=*`, fileData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${jwt}`,
                },
            })
            // window.location.reload();
            setOpen(false);
            console.log('Profile picture updated successfully!');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Unauthorized access. Please log in again.');
            } else {
                console.error('Error updating profile picture:', error);
            }
        }
    };

    // removing crcl image
    const DefaultImageUpload = async () => {
        const newData = {
            Profilepicture: null,
        }
        await axios.put(`${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users/${JSON.parse(localStorage.getItem("userData")).user.id}/?populate=*`, newData)
            .then(updatedData => {
                console.log('Data updated successfully:', updatedData);
                // window.location.reload();
            })
            .catch(error => {
                console.error('Error updating data:', error);
            });
    }

    const validateForm = () => {
        let isValid = true;

        if (!firstName) {
            setFirstNameError("First name is required");
            isValid = false;
        } else {
            setFirstNameError(null);
        }

        if (!lastName) {
            serLastNameError("Last name is required");
            isValid = false;
        } else {
            serLastNameError(null);
        }

        if (!password) {
            setPasswordError("Password is required");
            isValid = false;
        } else if (!strongPasswordRegex.test(password)) {
            isValid = false;
            setPasswordError(
                'Password must be at least 8 characters long and contain at least one letter, one number, and one special character.'
            );
        } else {
            setPasswordError(null);
        }

        if (!bio) {
            setBioError("Bio is required");
            isValid = false;
        } else {
            setBioError(null)
        }

        if (!designation) {
            setDesignationError("Designation is required");
            isValid = false
        } else {
            setDesignationError(null);
        }

        if (!location) {
            setLocationError("Location is required");
            isValid = false
        } else {
            setLocationError(null)
        }

        if (linkedIn !== null) {
            if (!UrlPatternRegex.test(linkedIn)) {
                setLinkedInError("Enter a valid LinkedIn url");
                isValid = false;
            } else {
                setLinkedInError(null);
            }
        }
        if (github !== null) {
            if (!UrlPatternRegex.test(github)) {
                setGithubError("Enter a valid Github url");
                isValid = false;
            } else {
                setGithubError(null);
            }
        }

        return isValid;
    }

    //   Update Prfile page handler
    const SubmitHandlers = async (e) => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const NewFormat = {
                FirstName: firstName,
                LastName: lastName,
                Bio: bio,
                password: password,
                LinkedIn: linkedIn,
                Github: github,
                Location: location,
                Designation: designation
            }

            await UpdateOneUserData("users", id, NewFormat)
                .then(updatedData => {
                    console.log('Data updated successfully:', updatedData);
                    Navigate("/")
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Error updating data:', error);
                });
        }
    }

    return (
        <div className='SetupProfile'>
            <div className='SetupProfile-Container'>
                <div className='SetupProfile-section'>
                    <h2>You're setting up a new {CRCLName} account</h2>
                    <p>Already have {CRCLName}?{" "}
                        <span className='SignupLink'
                            onClick={() => {
                                Navigate("/login");
                                localStorage.clear();
                                window.location.reload();
                            }} >
                            Sign in with a different login
                        </span></p>
                </div>
                <div className='SetupUseDetailsBlock'>
                    <div className='CRCLImageBlock'>
                        {Mydata && <img
                            className='ImageRender'
                            src={ImagePreview || `${Mydata.Profilepicture && Mydata.Profilepicture !== null ?
                                `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${Mydata.Profilepicture.url}` :
                                ""}`}
                            alt='ImageRender' />
                        }
                        {/* Profile pic dropdown */}
                        <div class="btn-group CRCLUpdloadBtn">
                            <div type="button"
                                className="DropdownUpload"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <i class="fa-solid fa-pen"></i>
                                Edit
                            </div>
                            <div class="dropdown-menu">
                                <label
                                    class="dropdown-item"
                                    htmlFor="fileUpload"
                                >
                                    Upload a photo
                                </label>
                                <input
                                    type='file'
                                    id='fileUpload'
                                    accept="image/*"
                                    onChange={FileUpdateHandler}
                                    style={{ display: "none" }}
                                />
                                {Mydata && Mydata.Profilepicture && Mydata.Profilepicture === null &&
                                    <label
                                        class="dropdown-item"
                                        onClick={() => setWarningOpen(true)}>
                                        Remove photo
                                    </label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='TellUsContainer'>
                        <h3>Tell us about you</h3>
                        <p>We need to know a few things to set up your account.</p>
                    </div>
                    <div className='SetUpForm' >
                        <div className='InputFields'>
                            <label>First Name</label>
                            <input
                                type='text'
                                value={firstName}
                                onChange={(e) =>
                                    setFirstName(e.target.value)}
                            />
                            {firstName === "" || firstName === null ?
                                <div className='ErrorMsg'>
                                    {FirstNameError}</div>
                                : <></>}
                        </div>
                        <div className='InputFields'>
                            <label>Last Name</label>
                            <input
                                type='text'
                                value={lastName}
                                onChange={(e) =>
                                    setLastName(e.target.value)}
                            />
                            {lastName === "" || lastName === null ?
                                <div className='ErrorMsg'>
                                    {LastNameError}</div>
                                : <></>}
                        </div>
                        <div className='InputFields'>
                            <label>Email</label>
                            <input
                                disabled
                                type='email'
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.target.value)}
                            />
                        </div>
                        <div className='InputFields'>
                            <label>Bio</label>
                            <input
                                type='text'
                                value={bio}
                                onChange={(e) =>
                                    setBio(e.target.value)}
                            />
                            {bio === "" || bio === null ?
                                <div className='ErrorMsg'>
                                    {BioError}</div>
                                : <></>}
                        </div>
                        <div className='InputFields'>
                            <label>Password</label>
                            {showPassword ? (
                                <input
                                    type='text'
                                    name='password'
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); setShowPassword(false) }}
                                    onBlur={() => setShowPassword(false)}
                                />
                            ) : (
                                <input
                                    type='password'
                                    name='password'
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); setShowPassword(false) }}
                                    onBlur={() => setShowPassword(false)}
                                />
                            )}
                            <div className='EyeVisible'>
                                <div className='EyeIcon' onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <i className='fa-solid fa-eye'></i>
                                    ) : (
                                        <i className='fa-solid fa-eye-slash'></i>
                                    )}
                                </div>
                            </div>
                            {password === "" || (!strongPasswordRegex.test(password)) ?
                                <div className='ErrorMsg'> {passwordError}</div> :
                                <div></div>}
                        </div>
                        <div className='InputFields'>
                            <label>LinkedIn</label>
                            <input
                                type='text'
                                value={linkedIn}
                                onChange={(e) =>
                                    setLinkedIn(e.target.value)}
                            />
                            {linkedIn !== "" &&
                                (!UrlPatternRegex.test(linkedIn)) ?
                                <div className='ErrorMsg'  >
                                    {LinkedInError}</div>
                                : <div></div>}
                        </div>
                        <div className='InputFields'>
                            <label>Github</label>
                            <input
                                type='url'
                                value={github}
                                onChange={(e) =>
                                    setGithub(e.target.value)}
                            />
                            {github !== "" &&
                                (!UrlPatternRegex.test(github)) ?
                                <div className='ErrorMsg'  >
                                    {GithubError}</div>
                                : <div></div>}
                        </div>
                        <div className='InputFields'>
                            <label>Location</label>
                            <input
                                type='text'
                                value={location}
                                onChange={(e) =>
                                    setLocation(e.target.value)}
                            />
                            {location === "" || location === null ?
                                <div className='ErrorMsg'>
                                    {LocationError}</div>
                                : <></>}
                        </div>
                        <div className='InputFields'>
                            <label>Designation</label>
                            <input
                                type='text'
                                value={designation}
                                onChange={(e) =>
                                    setDesignation(e.target.value)}
                            />
                            {designation === "" || designation === null ?
                                <div className='ErrorMsg'>
                                    {DesignationError}</div>
                                : <></>}
                        </div>
                        <div className='InputFields'>
                            <button className='Login-Btn'
                                onClick={SubmitHandlers}>
                                Create account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup dialog for image cropting*/}
            {selectedFile ?
                <div
                    open={Open}
                    onClose={handleClickOpen}>
                    <Dialog
                        open={Open}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description">
                        <div className='UpdateporfilePopup'>
                            <DialogTitle>
                                <div className='DialogModelContainer'>
                                    <h1 className='CRCLInfoUpdate-Heading'>Crop your new profile picture</h1>
                                    <CloseIcon onClick={() => setOpen(false)} />
                                </div>
                            </DialogTitle>
                            <DialogContent dividers>
                                <AvatarEditor
                                    ref={editorRef}
                                    image={selectedFile}
                                    width={250}
                                    height={250}
                                    border={50}
                                    color={[255, 255, 255, 0.6]}
                                    scale={1.2}
                                    borderRadius={700}
                                    rotate={0}
                                />
                            </DialogContent>
                            <DialogActions>
                                <button
                                    className='SetProfilePictureBtn'
                                    onClick={handleCropImage}>
                                    Set new profile picture
                                </button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div> : <></>}
            {warningOpen ?
                <div>
                    <Dialog
                        open={warningOpen}
                        onClose={() => setWarningOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogContent dividers>
                            <DialogContentText >
                                <p className='PaymentParagraph'>
                                    Are you sure you want to reset your current avatar?
                                </p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button
                                className='removeProfilebtn'
                                onClick={() =>
                                    setWarningOpen(false)}>
                                Cancel
                            </button>
                            <button
                                className='removeProfilebtn'
                                onClick={
                                    DefaultImageUpload}>
                                OK
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                : <></>}
        </div>
    )
}

export default InvitedUserUpdateProfile