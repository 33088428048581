import React from 'react'

import { useNavigate } from 'react-router-dom';
import { Button, TextArea, Tag, TagSkeleton } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import './Onboarding.css';
const RoleOnboarding = () => {

    const navigate = useNavigate();
    const handleButtonclick = () => {
        navigate('/thank-you');
    }


    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Invite your team to begin your ESG Audyting</div>
                <div className="Onboarding-Body">
                    User will be added to Audyt Organization Name once the user accepts the invitation sent on the given email address. 
                </div>
                <div className="invite-onboarding-form">

                   

                </div>
                <Button className="onboarding-button" onClick={handleButtonclick} renderIcon={ArrowRight}>
                    Set up your Audyt Organisation
                </Button>
            </div>
        </div>
    )
}

export default RoleOnboarding