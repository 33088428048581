import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from '@carbon/react';
import { ArrowRight, Information } from '@carbon/icons-react';

import Twostep from '../Images/two-step-image.svg';
import OneStep from '../Images/OneStep.svg';
import NoApproval from '../Images/NoApproval.svg';

const OnboardingApproval = () => {
  const navigate = useNavigate();
  const [selectedApproval, setSelectedApproval] = useState();
  const [SelectionError,setSelectionError]=useState('');
  const [ValidateCondition, setValidateCondition] = useState(false);

  const Validation=()=>{
    let isValid=true;

    if(!selectedApproval){
      isValid=false;
      setSelectionError("Approval type is required.")
    }else{
      setSelectionError(null)
    }

    return isValid
  }

  useEffect(()=>{
    Validation()
  },[])

  const handleButtonclick = () => {
    const Validate=Validation();
    if(Validate){
      setValidateCondition(false);
    navigate('/invite-your-team');
    sessionStorage.setItem("Approval_Permission",JSON.stringify(selectedApproval));
    }else{
      setValidateCondition(true);
    }
  };

  const ApprovalData=JSON.parse(sessionStorage.getItem("Approval_Permission"));
  useEffect(()=>{
    if(ApprovalData!==null){
    setSelectedApproval(ApprovalData)
    }else{
      setSelectedApproval("No approval flow")
    }
  },[ApprovalData])

  console.log("selectedApproval",selectedApproval)


  const approval_list = ["No approval flow", "One Step Approval", "Two Step Approval"];
  const getImageForApproval = () => {
    switch (selectedApproval&&selectedApproval) {
      case "One Step Approval":
        return <img src={OneStep} alt='approval' className='approval-image' />;
      case "Two Step Approval":
        return <img src={Twostep} alt='approval' className='approval-image' />;
      default:
        return <img src={NoApproval} alt='approval' className='approval-image' />;
    }
  }
  const getTextInfo = () => {
    switch (selectedApproval&&selectedApproval) {
      case "One Step Approval":
        return <div className='info'>
         <div><Information  size={16}/></div> 
          <div>A one step approval flow is when a user in your team can validate the entered data point to minimise errors and improve the quality of the report.</div>
        </div>;
      case "Two Step Approval":
        return <div className='info'>
         <div><Information  size={16}/></div> 
          <div className='info-container'>A two step approval flow is when two users in your team can validate the entered data point to minimise errors and improve the quality of the report.</div>
        </div>
      default:
        return <div className='info'>
          <div><Information  size={16}/></div> 
          <div className='info-container'>When there's no approval flow, your team enters data directly into the report without any additional checks. This isn't recommended because it raises the risk of errors in your data entry.</div>
        </div>;
    }
  };

  return (
    <div className='onboarding-container'>
      <img src="logo.svg" alt='logo' />
      <div className='onboarding-content'>
        <div className='Onboarding-Heading'>Choose an Approval Flow</div>
        <div className='Onboarding-Body'>Add a common approval flow for your team to your data points to minimize errors in your reporting.</div>
        <div className='approval-form'>
         {selectedApproval&&<Dropdown
            id="approvalType"
            titleText="Approval Type"
            invalid={ValidateCondition&&!selectedApproval}
            invalidText={SelectionError}
            items={approval_list}
            initialSelectedItem={selectedApproval?selectedApproval:approval_list[0]}
            onChange={(event) => setSelectedApproval(event.selectedItem)}
          />
          }
        </div>
        <div className='helper-container'>
          {getTextInfo()}
        </div>
        {getImageForApproval()}
        <Button className='onboarding-button' onClick={handleButtonclick} renderIcon={ArrowRight}>
          Choose an approval flow
        </Button>
      </div>

    </div>
  );
};

export default OnboardingApproval;
