import React from 'react';

const AudytLLM = () => {
    return (
        <div>
            AudytLLM
        </div>
    );
};

export default AudytLLM;