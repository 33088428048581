
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLoginJWT, LoginUserGrabJwt } from '../../APIController/AdministrationController';
import { Button, Header, HeaderName, TextInput } from '@carbon/react';

import { ArrowRight, Cloud, Email } from '@carbon/icons-react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Information } from '@carbon/icons-react';
import { createCode } from "supertokens-web-js/recipe/passwordless";
import { passwordlessCode } from '../SupertokenController';
import { FilterUser, getAlldata } from '../../APIController/APIControllers';


const PasswordlessLogin = () => {
    const [email, setEmail] = useState('');
    const [ListOfUsers, setListOfUsers] = useState(null);
    const [VerificationError, setVerificationError] = useState('');
    const [ValidateTest, setValidateTest] = useState(false);
    const navigate = useNavigate();
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const handleOnNavigate = async () => {
        navigate('/signup')
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    useEffect(() => {
        FilterUser(email)
            .then((data) => {
                setListOfUsers(data)
                console.log("data", data)
            })
            .catch((err) => {
                console.log("error", err)
            })
    }, [email])

    const Validation = () => {
        let IsValid = true;

        if (!email) {
            setVerificationError("Email is required");
            IsValid = false;
        } else if (!EmailRegex.test(email)) {
            setVerificationError("Enter a Valid email format");
            IsValid = false;
        } else {
            setVerificationError(null)
        }
        return IsValid;
    }


    const NavigateOTP = async () => {

        const Validate = Validation();
        console.log("Validate", Validate)
        if (Validate) {
            setValidateTest(false)
            // navigate('/verify-login') // for navigation to the next page
            const OTPBody = await passwordlessCode(email);
            console.log(OTPBody)
            sessionStorage.setItem('email', email)
            sessionStorage.setItem('deviceId', OTPBody.deviceId);
            sessionStorage.setItem('preAuthSessionId', OTPBody.preAuthSessionId);
            navigate('/verify-login')
            // You can add further logic here for navigation or other actions
        } else {
            setValidateTest(true)
        }
    }


    return (
        <div className="onboarding-container">
            <img src="logo.svg" alt="logo" />
            <div className="onboarding-content">
                <div className="Onboarding-Heading">Sign in to Audyt ESG</div>
                <div className="Onboarding-Body">
                    Welcome to Audyt, our mission is to transform companies
                    with precision analytics and AI for a sustainable future.
                </div>
                <div className='onboarding-form'>
                    <TextInput
                        id="email"
                        required
                        type="text"
                        labelText="Work email"
                        value={email}
                        invalid={ValidateTest}
                        invalidText={VerificationError}
                        placeholder='name@example.com'
                        onChange={handleEmailChange} />
                </div>

                <Button className='primary-login-button' onClick={NavigateOTP} renderIcon={Email}>
                    Recieve your verification code
                </Button>

                <div className='helper-container'>
                    <Information className='icon' />
                    We recommend signing in with your work email
                </div>

                {/* <div className='Signup-container'>
                    Don't have an account yet? &nbsp;

                    <div className='bold-link' onClick={handleOnNavigate}> {" "}Sign up {">"}</div>
                </div> */}
            </div>
        </div>
    )
}

export default PasswordlessLogin