import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '@carbon/react';
import { getAlldata } from '../../APIController/APIControllers';

const Logs = () => {
    const [DynamicData, setDynamicData] = useState([]);
    const params_pathId = "logs";
    const collectionName = params_pathId?.replace("-", " ").split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

    useEffect(() => {
        getAlldata(collectionName)
            .then((data) => {
                setDynamicData(data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const headers = ["Log ID", "Metric", "Status", "View Data Entry"];

    return (
        <div className='Container-container'>
            <h1 className='Heading'>Logs</h1>
            <div className='PageDescription'>
                Monitor every data that is being entered, approved, and rejected by your team.
            </div>
            <div className='DataTable'>
                <Table size="md" useZebraStyles={false} aria-label="sample table" experimentalAutoAlign={true}>
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableHeader key={header}>
                                    {header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(DynamicData) && DynamicData.map((logs) => (
                            <TableRow key={logs.id}>
                                <TableCell>{logs.id}</TableCell>
                                <TableCell>{logs.attributes.Metric}</TableCell>
                                <TableCell>{logs.attributes.Status}</TableCell>
                                <TableCell>View</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default Logs;
